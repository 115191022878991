import React, { memo, useState } from 'react'
import {
  Text,
  Flex,
  Box,
  Stack,
  Avatar,
  Icon,
  Button,
  Divider,
  Badge,
  Tag,
  Link
} from '@chakra-ui/core'
import { Position } from 'react-flow-renderer'
import { Vertex, getVariantColor, getFontColor, Indicator } from './PlatformNode'
import { images, theme } from '../../../theme'
import { isValidConnection } from '../index'
import { useQuoteContext } from '../../../context/QuoteProvider'

type FeatureData = {
  name: string
  description: string
  featureTime: number
  status: string
  parent: string
  template: Object
  comments: Comment[]
  custom: boolean
  integration: boolean
}

export type Comment = {
  id: string
  body: string
  user: {
    username: string
    url: string
  }
}

export type FeatureNodeProps = {
  id: string
  type: string
  data: FeatureData
  feature: any
}

//A function that determines the node indicator color based on type
export const getFeatureType = (custom: boolean, integration: boolean) => {
  switch (custom) {
    case true:
      return theme.colors.accent[500]
    case false:
      if (integration === true) {
        return theme.colors.red[400]
      } else {
        return theme.colors.brand[500]
      }
  }
}

const FeatureNode: React.FC<FeatureNodeProps> = (props) => {
  const [readMore, setReadMore] = useState<boolean>(false)
  const { setSelectedNode, onOpenDetailsDrawer, clientView } = useQuoteContext()
  const linkName = readMore ? 'Read Less' : 'Read More'
  let summary = null
  let details
  if (props.data.description?.length > 264) {
    summary = <Text>{props.data.description.slice(0, 314)}</Text>
    details = <Text>{props.data.description.slice(315, -1)}</Text>
  }
  return (
    <Flex mr={-8} mb="1px">
      <Box
        width="lg"
        mx="auto"
        bg="white"
        rounded="6px 0px 0px 6px"
        padding="0 0 0 4"
        shadow="1px 2px 4px"
        pr="6"
      >
        <Vertex type="target" position={Position.Top} isValidConnection={isValidConnection} />
        <Stack direction="row" spacing="3" align="stretch" height="auto" minH="185px">
          <Indicator color={getFeatureType(props.data?.custom, props.data?.integration)} />
          <Stack spacing="4" mr={2} mt={4}>
            <Avatar
              size="xl"
              src={images.feature}
              name="Standard Feature"
              bg="brand.700"
              color="white"
            />
            <Button
              backgroundColor="brand.100"
              alignItems="center"
              width="min-content"
              mx="auto"
              _hover={{ bg: 'brand.200' }}
              onClick={() => {
                setSelectedNode({
                  id: props.id,
                  type: 'featureNode',
                  data: props.data,
                  position: { x: 0, y: 0 }
                })
                onOpenDetailsDrawer()
              }}
            >
              <Icon name="edit" mr={1} size="13px" /> Edit
            </Button>
          </Stack>
          <Box ml={2} my={4}>
            <Stack spacing="4" direction="row" alignItems="center">
              <Text as="h2" fontWeight="bold" lineHeight={1.2} mb={1} mr={1} fontSize="2xl">
                {props.data?.name || ''}
              </Text>
              <Badge
                bg={getVariantColor(props.data.status)}
                variant="solid"
                color={getFontColor(props.data.status)}
                key={props.id}
                maxWidth="130px"
                height="min-content"
                fontSize="md"
              >
                {props.data?.status || ''}
              </Badge>
            </Stack>
            {!clientView && (
              <Stack direction="row" spacing="2" align="center">
                <Icon name="time" size="14px" />
                <Text fontWeight="semibold">{props.data?.featureTime || '0'} Hrs</Text>
              </Stack>
            )}
            <Divider bg="gray.500" />
            <Box fontSize="sm" fontWeight="light" width="350px" minH="50px">
              {summary === null ? (
                <Text>{props.data?.description || ''}</Text>
              ) : (
                <Text>
                  {summary}
                  {readMore && details}
                  <Link
                    fontWeight="normal"
                    onClick={() => {
                      setReadMore(!readMore)
                    }}
                    textDecoration="underline"
                  >
                    {linkName}
                  </Link>
                </Text>
              )}
            </Box>
            <Tag bg="gray.200" pr="3" mr={4} alignItems="center">
              <Icon name="chat" mr={1} size="12px" /> Comments (
              {props.data?.comments.filter((c: any) => !c.deleted)?.length || '0'})
            </Tag>
          </Box>
        </Stack>
      </Box>
    </Flex>
  )
}
export default memo(FeatureNode)
