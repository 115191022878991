import React, { useState } from 'react'
import {
  Flex,
  Button,
  Text,
  Badge,
  Input,
  Icon,
  InputGroup,
  InputLeftElement,
  useToast
} from '@chakra-ui/core'
import { ModalWrap, Table } from '../../../../components'
import { Column } from 'react-table'
import { useQuoteContext } from '../../../../context/QuoteProvider'
import { FeatureTemplate, useFeatureTemplatesQuery } from '../../../../generated/graphql'
import { get } from 'lodash'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'
import CustomFeatureModal from './newCustFeatureModal'

type FeatureModalProps = {
  addStdFeature: any
  addCustFeature: any
  setSelected: any
  onClose: any
  isOpen: any
  openCust: any
  openStd: any
}

const FeatureModal: React.FC<FeatureModalProps> = (props) => {
  // const [available, setAvailable] = useState<any[]>(dummyFeatures)
  const {
    parent,
    clientView,
    setSelectedFeature,
    isOpenCustomFeature,
    onCloseCustomFeature,
    onOpenFeatureModal
  } = useQuoteContext()
  const [searchTerm, setSearchTerm] = useState('')
  const toast = useToast()

  const updateSearch = (e: any) => {
    const term = e.target.value
    setSearchTerm(term)
  }

  const { data, loading, refetch } = useFeatureTemplatesQuery({
    variables: {
      sort: 'created_at:desc',
      where: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        name_contains: searchTerm
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const columns: Column[] = !clientView
    ? [
        { Header: 'Name', accessor: (row: any) => <Text fontWeight="semibold">{row.name}</Text> },
        {
          Header: 'Description',
          accessor: (row: any) => <Text isTruncated>{row.description}</Text>
        },
        {
          Header: 'Hours',
          accessor: (row: any) => (
            <Badge
              bg="brand.100"
              color="brand.600"
              variant="solid"
              key={row.id}
              maxWidth="170px"
              mr={1}
            >
              <Text>{row.hours} Hrs</Text>
            </Badge>
          )
        },
        {
          Header: '  ',
          accessor: (row: any) => (
            <Button
              key={row.id}
              onClick={() => {
                setSelectedFeature({
                  id: row.id,
                  name: row.name,
                  description: row.description,
                  hours: row.hours,
                  template: row.template,
                  integration: row.integration,
                  custom: row.custom
                })
                props.openStd()
                setSearchTerm('')
                props.onClose()
              }}
            >
              View Details
            </Button>
          )
        },
        {
          Header: ' ',
          accessor: (row: any) => (
            <Button
              key={row.id}
              variantColor="brand"
              onClick={() => {
                try {
                  if (row.custom === true) {
                    props.addCustFeature(row.name, row.description, {}, parseInt(row.hours), parent)
                  } else {
                    props.addStdFeature(
                      row.name,
                      row.description,
                      parseInt(row.hours),
                      parent,
                      row.id,
                      row.integration
                    )
                  }
                  toast({ description: `New Feature added.`, ...SUCCESS_TOAST })
                  setSearchTerm('')
                  props.onClose()
                } catch (error) {
                  toast({ description: `Error adding Feature.`, ...ERROR_TOAST })
                }
              }}
            >
              Quick Add
            </Button>
          )
        }
      ]
    : [
        { Header: 'Name', accessor: (row: any) => <Text fontWeight="semibold">{row.name}</Text> },
        {
          Header: 'Description',
          accessor: (row: any) => <Text isTruncated>{row.description}</Text>
        },
        {
          Header: '  ',
          accessor: (row: any) => (
            <Button
              key={row.id}
              onClick={() => {
                setSelectedFeature({
                  id: row.id,
                  name: row.name,
                  description: row.description,
                  hours: row.hours,
                  template: row.template,
                  integration: row.integration,
                  custom: row.custom
                })
                props.openStd()
                setSearchTerm('')
                props.onClose()
              }}
            >
              View Details
            </Button>
          )
        },
        {
          Header: ' ',
          accessor: (row: any) => (
            <Button
              key={row.id}
              variantColor="brand"
              onClick={() => {
                try {
                  if (row.custom === true) {
                    props.addCustFeature(row.name, row.description, {}, parseInt(row.hours), parent)
                  } else {
                    props.addStdFeature(
                      row.name,
                      row.description,
                      parseInt(row.hours),
                      parent,
                      row.id,
                      row.integration
                    )
                  }
                  toast({ description: `New Feature added.`, ...SUCCESS_TOAST })
                  setSearchTerm('')
                  props.onClose()
                } catch (error) {
                  toast({ description: `Error adding Feature.`, ...ERROR_TOAST })
                }
              }}
            >
              Quick Add
            </Button>
          )
        }
      ]

  const features = get(data, 'featureTemplates', [])

  return (
    <>
      <CustomFeatureModal
        isOpen={isOpenCustomFeature}
        onClose={onCloseCustomFeature}
        openFeat={onOpenFeatureModal}
        addCustFeature={props.addCustFeature}
        parent={parent}
        refetch={refetch}
        loading={loading}
      />
      <ModalWrap
        title="Add a Feature"
        isOpen={props.isOpen}
        onClose={() => {
          setSearchTerm('')
          props.onClose()
        }}
        size="4xl"
      >
        <Flex m={3} direction="column">
          <Flex>
            <InputGroup width="55%">
              <InputLeftElement
                pointerEvents="none"
                children={<Icon name="search" mr={1} size="15px" color="grey.400" />}
              />
              <Input
                name="filter"
                onChange={updateSearch}
                placeholder="Search features"
                borderRadius={2}
              />
            </InputGroup>
            <Button
              backgroundColor="brand.500"
              color="white"
              ml="auto"
              px={4}
              onClick={() => {
                setSearchTerm('')
                props.openCust()
                props.onClose()
              }}
            >
              Add Custom Feature
            </Button>
          </Flex>
          <Table
            columns={columns}
            isLoading={loading}
            tableHeading=""
            //TO-DO: server-side pagination here
            data={features as FeatureTemplate[]}
          />
        </Flex>
      </ModalWrap>
    </>
  )
}

export default FeatureModal
