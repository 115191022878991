import React from 'react'
import { Flex, Button, Text, Divider, useToast } from '@chakra-ui/core'
import { Formik, Form, FormikProps } from 'formik'
import { ConnectedFormGroup } from '../../../../components/FormElements'
import ConnectedTextarea from '../../../../components/FormElements/ConnectedTextArea'
import { AddNodeFormValidation } from '../../index'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../../constants'
import { formatError } from '../../../../utils'
import { ModalWrap } from '../../../../components'

type ComponentModalProps = {
  addComponent: any
  onClose: any
  isOpen: any
  parent: string
}

type InitialValues = {
  name: string
  description: string
}

const ComponentModal: React.FC<ComponentModalProps> = (props) => {
  const toast = useToast()
  return (
    <ModalWrap title="New Component Node" isOpen={props.isOpen} onClose={props.onClose}>
      <Formik
        validationSchema={AddNodeFormValidation}
        initialValues={{
          name: '',
          description: ''
        }}
        onSubmit={({ name, description }, { setStatus }) => {
          try {
            props.addComponent(name, description, props.parent)
            toast({ description: `New Component added.`, ...SUCCESS_TOAST })
            props.onClose()
          } catch (error) {
            setStatus(formatError(error))
            toast({ description: `Error adding Component.`, ...ERROR_TOAST })
          }
        }}
      >
        {({ status, handleSubmit }: FormikProps<InitialValues>) => {
          return (
            <Form style={{ width: '100%' }}>
              <Flex flexDir="column" p={4}>
                <ConnectedFormGroup
                  name="name"
                  label="Name"
                  placeholder={`What should we call this Component?`}
                />
                <ConnectedTextarea
                  name="description"
                  label="Description"
                  placeholder={`Tell us a bit about what this Component does?`}
                />
                {status && (
                  <Text textAlign="right" color="red.500">
                    {status}
                  </Text>
                )}
                <Divider marginBottom={3} />
                <Flex>
                  <Button onClick={handleSubmit} variantColor="brand" marginX={2} paddingX={6}>
                    Done
                  </Button>
                  <Button
                    onClick={() => {
                      props.onClose()
                    }}
                    marginX={2}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </ModalWrap>
  )
}

export default ComponentModal
