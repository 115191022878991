import React from 'react'
import {
  Flex,
  Button,
  Text,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Box,
  useToast,
  Icon,
  Grid,
  DrawerCloseButton,
  DrawerBody
} from '@chakra-ui/core'
import {
  useUpdateQuoteMutation,
  useCreateSolutionOverviewMutation,
  useCreatePmeDocMutation,
  Enum_Quote_Status,
  useQuoteReviewStatusMutation,
  useQuoteApprovedStatusMutation
} from '../../../../generated/graphql'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { ConnectedNumberInput } from '../../../../components/FormElements'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../../constants'
import { formatError } from '../../../../utils'
import { Stepper } from '../../../../components'
import { H2, H3 } from '../../../../typography'
import { useQuoteContext } from '../../../../context/QuoteProvider'
import { useAuthContext } from '../../../../context/AuthProvider'

type ApprovalDrawerProps = {
  onClose: () => void
  isOpen: boolean
  refetch: () => any
  data: any
}

export const getStep = (status: any) => {
  switch (status) {
    case Enum_Quote_Status.New:
      return 0
    case Enum_Quote_Status.Review:
      return 1
    case Enum_Quote_Status.Accepted:
      return 1
    case Enum_Quote_Status.Approved:
      return 2
    default:
      return 0
  }
}

const ApprovalDrawer: React.FC<ApprovalDrawerProps> = (props) => {
  const toast = useToast()
  const params: any = useParams()
  const { user } = useAuthContext()
  const { isOpenApprovalDrawer, onCloseApprovalDrawer, approvalReady } = useQuoteContext()

  const [updateQuote] = useUpdateQuoteMutation({
    onCompleted: () => {
      toast({ description: 'Quote updated.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: () => {
      toast({ description: 'There was an error updating quote.', ...ERROR_TOAST })
    }
  })

  const [quoteReviewStatus, { loading: isLoadingQuoteReviewStatus }] = useQuoteReviewStatusMutation(
    {
      onCompleted: () => {
        toast({ description: 'Quote status updated.', ...SUCCESS_TOAST })
        props.refetch()
      },
      onError: () => {
        toast({ description: 'There was an error updating quote status.', ...ERROR_TOAST })
      }
    }
  )

  const [
    quoteApprovedStatus,
    { loading: isLoadingQuoteApprovedStatus }
  ] = useQuoteApprovedStatusMutation({
    onCompleted: () => {
      toast({ description: 'Quote status updated.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: () => {
      toast({ description: 'There was an error updating quote status.', ...ERROR_TOAST })
    }
  })

  const [
    createSolutionOverviewMutation,
    { loading: createSolutionOverviewLoading }
  ] = useCreateSolutionOverviewMutation({
    onCompleted: () => {
      toast({ description: 'Solution Overview created.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: () => {
      toast({ description: 'There was an error creating the Solution Overview.', ...ERROR_TOAST })
    }
  })

  const [createPMEDocMutation, { loading: createPMEDocLoading }] = useCreatePmeDocMutation({
    onCompleted: () => {
      toast({ description: 'Platform Module Epic created.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: () => {
      toast({
        description: 'There was an error creating the Platform Module Epic .',
        ...ERROR_TOAST
      })
    }
  })

  const createSolutionOverview = async () => {
    const { data } = await createSolutionOverviewMutation({ variables: { id: params.id } })
    window.open(data?.createSolutionOverview?.url || '', '_blank')
  }

  const createPmeDoc = async () => {
    const { data } = await createPMEDocMutation({ variables: { id: params.id } })
    window.open(data?.createPMEDoc?.url || '', '_blank')
  }

  const timelineTotal = props.data?.quote?.hours

  return (
    <Drawer
      size="md"
      placement="right"
      isOpen={isOpenApprovalDrawer}
      onClose={onCloseApprovalDrawer}
    >
      <DrawerOverlay />
      <DrawerContent overflowY="scroll">
        <DrawerCloseButton mb={4} />
        <DrawerBody>
          <Box p={4} display="flex" flexDirection="column">
            <Formik
              initialValues={{
                designPercentage: 15
              }}
              onSubmit={async (values, { setSubmitting, setStatus }) => {
                setStatus(null)
                try {
                  setSubmitting(true)
                  await updateQuote({
                    variables: {
                      id: params.id,
                      data: {
                        design: timelineTotal * values.designPercentage * 0.01
                      }
                    }
                  })
                  props.refetch()
                  setSubmitting(false)
                } catch (error) {
                  setStatus(formatError(error))
                }
              }}
            >
              {({ values, isSubmitting, submitForm, setFieldValue }) => (
                <Stepper activeStep={getStep(props.data?.quote?.status)}>
                  <Flex px={4} flexDirection="column">
                    <H3>Step 1: Build Quote</H3>
                    <Divider />
                    <Text>
                      Use the panel to the left to select all features required under each platform.
                      Feel free to add comments to provide better context around the selected
                      features and how they're intended use within the application. <br /> <br />
                      The custom integration button allows you to specify any custom integrations
                      that are required, eg. Stripe for payments. <br /> <br /> The custom feature
                      button should be used to specify all features that are required, but fall
                      outside of the default feature set.
                    </Text>
                    <Divider />
                    <Button
                      variantColor="brand"
                      variant="outline"
                      isLoading={isLoadingQuoteReviewStatus}
                      onClick={async () => {
                        await quoteReviewStatus({
                          variables: {
                            id: params.id
                          }
                        })
                        props.refetch()
                        submitForm()
                      }}
                    >
                      Request Review
                    </Button>
                    <Text fontSize="sm" color="gray.600" mt={4}>
                      <Icon name="info" mr={1} size="15px" />
                      Once you've configured your quote with all the needed features, you can submit
                      the quote for review & approval.
                    </Text>
                  </Flex>
                  <Flex px={4} flexDirection="column">
                    <H3>Step 2: Review Quote</H3>
                    <Divider />
                    {user?.role?.name === 'Architect' ? (
                      <>
                        <Text>
                          This quote has been submitted for approval and is currently awaiting
                          review. Upon review completion, a final timeline and price estimate will
                          be made available.
                        </Text>
                        <H3 fontWeight="bold" mt={4} color="red.500">
                          {timelineTotal} hours
                        </H3>
                        <H3 fontSize="medium" fontWeight="bold" mb={4} color="red.500">
                          {(timelineTotal / 40).toFixed(1)} Weeks
                        </H3>
                        <Text>
                          Until then, a provisional estimate is available above. Please note that
                          this should be viewed as a minimum estimate as it would not accurately
                          account for specifics, custom features and integrations until a review has
                          been completed.
                        </Text>
                        <Divider />
                        <Button
                          mt={4}
                          width="100%"
                          variantColor="red"
                          variant="outline"
                          isLoading={isSubmitting}
                          onClick={() => {
                            setFieldValue('status', Enum_Quote_Status.New)
                            submitForm()
                          }}
                        >
                          Cancel Review
                        </Button>
                        <Text fontSize="sm" color="gray.600" mt={4}>
                          <Icon name="info" mr={1} size="15px" />
                          You may cancel this review request to make adjustments to the feature set
                          if need be.
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text>
                          All required features have been captured and context provided by way of
                          comments under each individual feature. <br /> <br /> Review each
                          individual feature and provide any further insight you may have that would
                          impact the timeline or price by adding a comment to the respective
                          feature. <br /> <br /> Additionally you may adjust the timeline for the
                          feature by clicking on the "View" button.
                        </Text>
                        <Divider />
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                          <ConnectedNumberInput
                            name="designPercentage"
                            label="Design Percentage"
                            unit="%"
                            precision={2}
                          />
                          <Flex flexDirection="column">
                            <Text color="brand.500">Total Estimate</Text>
                            <Text color="brand.500" fontWeight="bold" py={2}>
                              {timelineTotal + timelineTotal * values.designPercentage * 0.01} hours
                            </Text>
                          </Flex>
                          <Flex flexDirection="column">
                            <Text>Development Timeline</Text>
                            <Text fontWeight="bold" py={2}>
                              {timelineTotal} hours
                            </Text>
                          </Flex>
                          <Flex flexDirection="column">
                            <Text>Design Timeline</Text>
                            <Text fontWeight="bold" py={2}>
                              {timelineTotal * values.designPercentage * 0.01} hours
                            </Text>
                          </Flex>
                        </Grid>
                        <Button
                          mt={4}
                          width="100%"
                          variantColor="brand"
                          variant="outline"
                          isLoading={isLoadingQuoteApprovedStatus}
                          isDisabled={!approvalReady}
                          onClick={async () => {
                            await quoteApprovedStatus({
                              variables: {
                                id: params.id
                              }
                            })
                            props.refetch()
                            submitForm()
                          }}
                        >
                          Approve Quote
                        </Button>
                        <Text fontSize="sm" color="gray.600" mt={4}>
                          <Icon name="info" mr={1} size="15px" />
                          Once you've made any necessary comment adjustments to the features and you
                          can approve the quote.
                        </Text>
                      </>
                    )}
                  </Flex>
                  <Flex px={4} flexDirection="column">
                    <H3>Step 3: Quote Summary</H3>
                    <Divider />
                    <Text>
                      The quote, including all platforms and features have been configured and a
                      price and timeline has been approved. <br /> Below is a summary of the
                      estimated cost and timeline for the build.
                    </Text>
                    <H3 mt={3}>Estimated Development Timeline</H3>
                    <H2 fontWeight="bold" color="brand.500">
                      {Math.ceil(timelineTotal / 160)} Months
                    </H2>

                    <H3 mt={3}>Estimated Design Timeline</H3>
                    <H2 fontWeight="bold" color="brand.500">
                      {((timelineTotal * values.designPercentage * 0.01) / 160).toFixed(1)} Months
                    </H2>

                    <H3 mt={3}>Total Estimated Timeline</H3>
                    <H2 fontWeight="bold" color="brand.500">
                      {(
                        Math.ceil(timelineTotal / 160) +
                        (timelineTotal * values.designPercentage * 0.01) / 160
                      ).toFixed(1)}{' '}
                      Months
                    </H2>

                    <Divider />
                    <Text>
                      Use the controls below to generate a solution overview presentation that can
                      be presented to the client, with all the necessary details already filled out.
                    </Text>
                    <Divider />
                    <Button
                      onClick={createSolutionOverview}
                      variantColor="brand"
                      isLoading={createSolutionOverviewLoading}
                    >
                      Generate Solution Overview
                    </Button>
                    <Divider />
                    <Button
                      onClick={createPmeDoc}
                      variantColor="brand"
                      isLoading={createPMEDocLoading}
                      mb={2}
                    >
                      Generate PME Grid
                    </Button>
                  </Flex>
                </Stepper>
              )}
            </Formik>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default ApprovalDrawer
