import {
  Flex,
  Text,
  Heading,
  Badge,
  Divider,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { useAppContext } from '../../../context/AppProvider'
import { useAuthContext } from '../../../context/AuthProvider'
import { useQuoteContext } from '../../../context/QuoteProvider'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
    clientName: string | null | undefined
    projectName: string | null | undefined
    projectID: string | null | undefined
    quoteID: string | null | undefined
    quoteName: string | null | undefined
    timeline?: number | null | undefined
    partner?: boolean | null | undefined
    // timelineCustom: number
  }

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1290;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: space-between;
  left: 0px;
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const history = useHistory()
  const { drawerOpen } = useAppContext()
  const { user } = useAuthContext()

  const {
    onOpenEditQuoteModal,
    onOpenApprovalDrawer,
    onOpenPlatformModal,
    onOpenMakeTemplateModal,
    onOpenHistoryDrawer,
    clientView,
    isApproved
  } = useQuoteContext()

  return (
    <HeaderCont pr={4} pl={drawerOpen ? 'calc(186px + 1rem)' : '1rem'} {...rest}>
      <Button size="sm" leftIcon="arrow-back" onClick={() => history.goBack()}>
        Return
      </Button>
      <Flex flexDirection="row">
        <Flex flexDirection="column" justifyContent="space-between">
          <Heading size="sm" color="gray.700">
            {rest.clientName}
          </Heading>
          <Text>Client</Text>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flexDirection="column" justifyContent="space-between">
          <Heading size="sm" color="gray.700">
            {rest.projectName}
          </Heading>
          <Text>Project {rest.partner && <Badge variantColor="brand">Partner</Badge>}</Text>
        </Flex>
        <Divider orientation="vertical" />
        <Flex flexDirection="column" justifyContent="space-between">
          <Heading size="sm" color="gray.700">
            {rest.quoteName}
          </Heading>
          <Text>Solution</Text>
        </Flex>
        {user?.role?.name !== 'Client' && (
          <>
            <Divider orientation="vertical" />
            <Flex flexDirection="column" justifyContent="space-between">
              <Badge variantColor="brand" p={1} variant="solid">
                <Heading size="xs">
                  {clientView ? `Client View` : `${Math.ceil((rest.timeline || 0) / 160)} months`}
                </Heading>
              </Badge>
              <Text>Estimate</Text>
            </Flex>
          </>
        )}
      </Flex>
      <Flex flexDirection="row">
        {user?.role?.name !== 'Client' && (
          <>
            <Button
              mr={4}
              size="sm"
              onClick={onOpenPlatformModal}
              aria-label="Settings"
              variantColor="brand"
              isDisabled={isApproved}
            >
              Add Platform
            </Button>
            <Menu>
              <MenuButton as={Button}>
                Actions &nbsp;
                <Icon name="chevron-down" />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={onOpenEditQuoteModal}>Edit Quote Name</MenuItem>
                <MenuItem onClick={onOpenApprovalDrawer}>Review Quote</MenuItem>
                <MenuItem onClick={onOpenMakeTemplateModal}>Make Template</MenuItem>
                <MenuItem onClick={onOpenHistoryDrawer}>View History</MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
      </Flex>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'white'
}
