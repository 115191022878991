import { useDisclosure } from '@chakra-ui/core'
import * as React from 'react'

type QuoteContextType = {
  isOpenEditQuoteModal: boolean
  onOpenEditQuoteModal: () => void
  onCloseEditQuoteModal: () => void

  isOpenComponentModal: boolean
  onOpenComponentModal: () => void
  onCloseComponentModal: () => void

  isOpenFeatureModal: boolean
  onOpenFeatureModal: () => void
  onCloseFeatureModal: () => void

  isOpenCustomFeature: boolean
  onOpenCustomFeature: () => void
  onCloseCustomFeature: () => void

  isOpenStdFeature: boolean
  onOpenStdFeature: () => void
  onCloseStdFeature: () => void

  isOpenDetailsDrawer: boolean
  onOpenDetailsDrawer: () => void
  onCloseDetailsDrawer: () => void

  isOpenApprovalDrawer: boolean
  onOpenApprovalDrawer: () => void
  onCloseApprovalDrawer: () => void

  isOpenHistoryDrawer: boolean
  onOpenHistoryDrawer: () => void
  onCloseHistoryDrawer: () => void

  isOpenPlatformModal: boolean
  onOpenPlatformModal: () => void
  onClosePlatformModal: () => void

  isOpenMakeTemplateModal: boolean
  onOpenMakeTemplateModal: () => void
  onCloseMakeTemplateModal: () => void

  selectedNode: any
  setSelectedNode: any

  selectedFeature: any
  setSelectedFeature: any

  changedStatus: null | any
  setChangedStatus: any

  parent: any
  setParent: any

  isPlatButton: any
  setIsPlatButton: any

  allPlatforms: any
  setAllPlatforms: any

  approvalReady: any
  setApprovalReady: any

  clientView: boolean
  setClientView: any

  isApproved: boolean
  setIsApproved: any
}

export const QuoteContext = React.createContext<QuoteContextType>({
  isOpenEditQuoteModal: false,
  onOpenEditQuoteModal: () => null,
  onCloseEditQuoteModal: () => null,

  clientView: false,
  setClientView: () => null,

  isOpenComponentModal: false,
  onOpenComponentModal: () => null,
  onCloseComponentModal: () => null,

  isOpenFeatureModal: false,
  onOpenFeatureModal: () => null,
  onCloseFeatureModal: () => null,

  isOpenCustomFeature: false,
  onOpenCustomFeature: () => null,
  onCloseCustomFeature: () => null,

  isOpenStdFeature: false,
  onOpenStdFeature: () => null,
  onCloseStdFeature: () => null,

  isOpenDetailsDrawer: false,
  onOpenDetailsDrawer: () => null,
  onCloseDetailsDrawer: () => null,

  isOpenApprovalDrawer: false,
  onOpenApprovalDrawer: () => null,
  onCloseApprovalDrawer: () => null,

  isOpenHistoryDrawer: false,
  onOpenHistoryDrawer: () => null,
  onCloseHistoryDrawer: () => null,

  isOpenPlatformModal: false,
  onOpenPlatformModal: () => null,
  onClosePlatformModal: () => null,

  isOpenMakeTemplateModal: false,
  onOpenMakeTemplateModal: () => null,
  onCloseMakeTemplateModal: () => null,

  selectedNode: {
    data: { name: '', description: '' }
  },
  setSelectedNode: () => null,

  selectedFeature: {
    id: '',
    name: '',
    description: '',
    hours: 0,
    template: '',
    integration: false,
    custom: false
  },
  setSelectedFeature: () => null,

  changedStatus: null,
  setChangedStatus: () => null,

  parent: '',
  setParent: () => null,

  isPlatButton: '',
  setIsPlatButton: () => null,

  allPlatforms: null,
  setAllPlatforms: () => null,

  approvalReady: null,
  setApprovalReady: () => null,

  isApproved: false,
  setIsApproved: () => null
})

export const useQuoteContext = () => React.useContext(QuoteContext)

const QuoteProvider: React.FC<{}> = ({ children }) => {
  const {
    isOpen: isOpenEditQuoteModal,
    onOpen: onOpenEditQuoteModal,
    onClose: onCloseEditQuoteModal
  } = useDisclosure()

  const {
    isOpen: isOpenComponentModal,
    onOpen: onOpenComponentModal,
    onClose: onCloseComponentModal
  } = useDisclosure()

  const {
    isOpen: isOpenFeatureModal,
    onOpen: onOpenFeatureModal,
    onClose: onCloseFeatureModal
  } = useDisclosure()

  const {
    isOpen: isOpenCustomFeature,
    onOpen: onOpenCustomFeature,
    onClose: onCloseCustomFeature
  } = useDisclosure()

  const {
    isOpen: isOpenStdFeature,
    onOpen: onOpenStdFeature,
    onClose: onCloseStdFeature
  } = useDisclosure()

  const {
    isOpen: isOpenDetailsDrawer,
    onOpen: onOpenDetailsDrawer,
    onClose: onCloseDetailsDrawer
  } = useDisclosure()

  const {
    isOpen: isOpenApprovalDrawer,
    onOpen: onOpenApprovalDrawer,
    onClose: onCloseApprovalDrawer
  } = useDisclosure()

  const {
    isOpen: isOpenHistoryDrawer,
    onOpen: onOpenHistoryDrawer,
    onClose: onCloseHistoryDrawer
  } = useDisclosure()

  const {
    isOpen: isOpenPlatformModal,
    onOpen: onOpenPlatformModal,
    onClose: onClosePlatformModal
  } = useDisclosure()

  const {
    isOpen: isOpenMakeTemplateModal,
    onOpen: onOpenMakeTemplateModal,
    onClose: onCloseMakeTemplateModal
  } = useDisclosure()

  const [selectedNode, setSelectedNode] = React.useState<any>({
    data: { name: '', description: '' }
  })

  const [selectedFeature, setSelectedFeature] = React.useState<any>({
    id: '',
    name: '',
    description: '',
    hours: 0,
    template: '',
    integration: false,
    custom: false
  })

  const [changedStatus, setChangedStatus] = React.useState<any>(null)

  const [parent, setParent] = React.useState<string>('')
  const [clientView, setClientView] = React.useState<boolean>(false)

  const [isPlatButton, setIsPlatButton] = React.useState<boolean>(false)

  const [allPlatforms, setAllPlatforms] = React.useState<any>(null)

  const [approvalReady, setApprovalReady] = React.useState<boolean>(false)

  const [isApproved, setIsApproved] = React.useState<boolean>(false)

  return (
    <QuoteContext.Provider
      value={{
        isOpenEditQuoteModal,
        onOpenEditQuoteModal,
        onCloseEditQuoteModal,
        isOpenComponentModal,
        onOpenComponentModal,
        onCloseComponentModal,
        isOpenFeatureModal,
        onOpenFeatureModal,
        onCloseFeatureModal,
        isOpenCustomFeature,
        onOpenCustomFeature,
        onCloseCustomFeature,
        isOpenStdFeature,
        onOpenStdFeature,
        onCloseStdFeature,
        isOpenDetailsDrawer,
        onOpenDetailsDrawer,
        onCloseDetailsDrawer,
        isOpenApprovalDrawer,
        onOpenApprovalDrawer,
        onCloseApprovalDrawer,
        isOpenHistoryDrawer,
        onOpenHistoryDrawer,
        onCloseHistoryDrawer,
        isOpenPlatformModal,
        onOpenPlatformModal,
        onClosePlatformModal,
        isOpenMakeTemplateModal,
        onOpenMakeTemplateModal,
        onCloseMakeTemplateModal,
        selectedNode,
        setSelectedNode,
        selectedFeature,
        setSelectedFeature,
        changedStatus,
        setChangedStatus,
        parent,
        setParent,
        isPlatButton,
        setIsPlatButton,
        allPlatforms,
        setAllPlatforms,
        approvalReady,
        setApprovalReady,
        isApproved,
        setIsApproved,
        clientView,
        setClientView
      }}
    >
      {children}
    </QuoteContext.Provider>
  )
}

export default QuoteProvider
