import React, { memo, useState } from 'react'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Link,
  Stack,
  Tag,
  Text
} from '@chakra-ui/core'
import { isValidConnection, Node } from '../index'
import { Vertex, getVariantColor, getFontColor, Indicator } from './PlatformNode'
import { Position } from 'react-flow-renderer'
import { images, theme } from '../../../theme'
import FeatureNode from './FeatureNode'
import { useQuoteContext } from '../../../context/QuoteProvider'
import { useAuthContext } from '../../../context/AuthProvider'

type ComponentData = {
  name: string
  description: string
  hours: number
  comments: Comment[]
  status: string
  parent: string
  features: Object[]
}

//status to enum pending DB restructure

export type ComponentNodeProps = Node & {
  data: ComponentData
}

const ComponentNode: React.FC<ComponentNodeProps> = (props) => {
  const {
    onOpenComponentModal,
    onOpenFeatureModal,
    setSelectedNode,
    onOpenDetailsDrawer,
    setParent,
    setIsPlatButton,
    isApproved,
    clientView
  } = useQuoteContext()
  const { user } = useAuthContext()
  const [readMore, setReadMore] = useState<boolean>(false)
  const linkName = readMore ? 'Read Less' : 'Read More'
  let summary = null
  let details
  if (props.data.description?.length > 264) {
    summary = <Text>{props.data.description.slice(0, 314)}</Text>
    details = <Text>{props.data.description.slice(315, -1)}</Text>
  }
  return (
    <Flex alignItems="start" direction="column" mr={-1}>
      <Flex mb={0.5}>
        <Flex>
          <Box
            width="lg"
            mx="auto"
            bg="white"
            rounded="6px 0px 0px 6px"
            padding="0 0 0 4"
            shadow="1px 1px 4px"
            pr="6"
          >
            <Vertex
              type="target"
              position={Position.Left}
              top="130px"
              bottom="auto"
              isValidConnection={isValidConnection}
            />
            <Stack direction="row" spacing="3" align="stretch" height="auto" minH="185px">
              <Indicator color={theme.colors.green[300]} />
              <Stack spacing="4" mr={2} mt={4}>
                <Avatar
                  size="xl"
                  src={images.component}
                  name="Component"
                  bg="brand.700"
                  color="white"
                />
                <Button
                  backgroundColor="brand.100"
                  alignItems="center"
                  width="min-content"
                  mx="auto"
                  _hover={{ bg: 'brand.200' }}
                  onClick={() => {
                    setSelectedNode({
                      id: props.id,
                      type: props.type,
                      data: props.data,
                      position: props.position
                    })
                    onOpenDetailsDrawer()
                  }}
                >
                  <Icon name="edit" mr={1} size="13px" /> Edit
                </Button>
              </Stack>
              <Box ml={2} my={4}>
                <Stack spacing="4" direction="row" alignItems="center">
                  <Text as="h2" fontWeight="bold" mr={1} lineHeight={1.2} mb={1} fontSize="2xl">
                    {props.data?.name || ''}
                  </Text>
                  <Badge
                    bg={getVariantColor(props.data?.status)}
                    variant="solid"
                    color={getFontColor(props.data?.status)}
                    key={props.id}
                    maxWidth="130px"
                    height="min-content"
                    fontSize="md"
                  >
                    {props.data?.status || ''}
                  </Badge>
                </Stack>
                {!clientView && (
                  <Stack direction="row" spacing="2" align="center">
                    <Icon name="time" size="14px" />
                    <Text fontWeight="semibold">{props.data?.hours || '0'} Hrs</Text>
                  </Stack>
                )}
                <Divider bg="gray.500" />
                <Box fontSize="sm" fontWeight="light" width="350px" minH="50px">
                  {summary === null ? (
                    <Text>{props.data?.description || ''}</Text>
                  ) : (
                    <Text>
                      {summary}
                      {readMore && details}
                      <Link
                        fontWeight="normal"
                        onClick={() => {
                          setReadMore(!readMore)
                        }}
                        textDecoration="underline"
                      >
                        {linkName}
                      </Link>
                    </Text>
                  )}
                </Box>
                <Tag bg="gray.200" pr="3" mr={4} mt="auto" alignItems="center">
                  <Icon name="chat" mr={1} size="12px" /> Comments (
                  {props.data?.comments.filter((c: any) => !c.deleted)?.length || '0'})
                </Tag>
              </Box>
            </Stack>
            <Vertex
              type="source"
              position={Position.Right}
              top="130px"
              bottom="auto"
              isValidConnection={isValidConnection}
            />
          </Box>
          <Button
            aria-label=""
            bg="gray.300"
            height="100%"
            borderRadius="0px 4px 4px 0px"
            shadow="1px 1px 2px"
            my="auto"
            _hover={{ bg: 'brand.700', color: 'white' }}
            width="25px"
            onClick={() => {
              setParent(props.id)
              setIsPlatButton(false)
              onOpenComponentModal()
            }}
            isDisabled={isApproved || user?.role?.name === 'Client'}
          >
            <Flex transform="rotate(-90deg)" align="center">
              <Icon name="plus-square" my={2} mr={2} />
              New Component
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {props.data.features?.map((feat: any) => (
        <FeatureNode key={feat.id} id={feat.id} type={feat.type} feature={feat} data={feat.data} />
      ))}
      <Button
        bg="gray.300"
        leftIcon="plus-square"
        shadow="1px 1px 2px"
        borderRadius="0px 0px 4px 4px"
        alignItems="center"
        width="92.7%"
        mr="auto"
        _hover={{ bg: 'brand.700', color: 'white' }}
        onClick={() => {
          setParent(props.id)
          setIsPlatButton(false)
          onOpenFeatureModal()
        }}
        isDisabled={isApproved || user?.role?.name === 'Client'}
      >
        New Feature
      </Button>
    </Flex>
  )
}
export default memo(ComponentNode)
