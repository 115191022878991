import * as React from 'react'
import { STRAPI_USER_STORAGE_KEY } from '../../constants'
import { UsersPermissionsUser } from '../../generated/graphql'
import { useBrowserStorage } from '../../hooks'
import strapiHelpers, { StrapiLoginPayload } from '../../utils/strapiHelpers'

type AuthProviderProps = {
  logout: () => void
  isAuthenticating: boolean
  isAuthenticated: boolean
  isOnboarded: boolean
  user: UsersPermissionsUser
  register: (username: string, email: string, password: string) => Promise<void>
  login: (email: string, password: string, rememberMe: boolean) => Promise<void>
  oauth: (payload: string) => Promise<void>
}

const AuthContext = React.createContext<Partial<AuthProviderProps>>({})

export const useAuthContext = () => React.useContext(AuthContext)

type UserStorage = StrapiLoginPayload | null

const AuthProvider: React.FC = ({ children }) => {
  const [localUser, setLocalUser, removeLocalUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'local'
  )

  const [sessionUser, setSessionUser, removeSessionUser] = useBrowserStorage<UserStorage>(
    STRAPI_USER_STORAGE_KEY,
    'session'
  )

  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(true)
  const [isOnboarded, setIsOnboarded] = React.useState(false)

  const [user, setUser] = React.useState(sessionUser?.user || localUser?.user)

  const persistUser = (data: StrapiLoginPayload, rememberMe?: boolean) => {
    rememberMe ? setLocalUser(data) : setSessionUser(data)
    setUser(data?.user)
  }

  const logout = () => {
    removeLocalUser()
    removeSessionUser()
    setIsAuthenticated(false)
    setUser(undefined)
  }

  React.useEffect(() => {
    if (user?.confirmed) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    if (user?.memberID) {
      setIsOnboarded(true)
    } else {
      setIsOnboarded(false)
    }
    setIsAuthenticating(false)
  }, [user])

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const { data } = await strapiHelpers.login(email, password)
      persistUser(data, rememberMe)
    } catch (error) {
      throw error
    }
  }

  const oauth = async (payload: string) => {
    try {
      const { data } = await strapiHelpers.oauth(payload)
      persistUser(data)
    } catch (error) {
      throw error
    }
  }

  const register = async (username: string, email: string, password: string) => {
    try {
      const { data } = await strapiHelpers.register(username, email, password)
      persistUser(data)
    } catch (error) {
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        isAuthenticated,
        isAuthenticating,
        isOnboarded,
        user,
        oauth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
