import { Avatar, Flex } from '@chakra-ui/core'
import { AnimatePresence, motion, useAnimation, Variants } from 'framer-motion'
import * as React from 'react'
import { HelpCircle } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { ColorProps, get } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { useAuthContext } from '../../context/AuthProvider/index'
import { NavItem } from '../../navigation'
import { images, theme } from '../../theme'
import { Text } from '../../typography'
import Copyright from '../Copyright'
import Header from '../Header'
import MotionFlex from '../MotionFlex'
import SideBarButton from './SideBarButton'
import SideBarItem from './SideBarItem'
import SideBarSupportItem from './SideBarSupportItem'
import { MenuCont, Overlay, RenderWrapper } from './styles'

type SideBarProps = ColorProps & {
  accentColor: string
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  renderUserInfo?: (history: any) => React.ReactNode
  tooltipBg?: string
  tooltipColor?: string
}

const SideBar: React.FC<SideBarProps> = ({
  accentColor,
  bg,
  borderColor,
  children,
  color,
  hoverColor,
  navItems,
  tooltipBg,
  tooltipColor
}) => {
  const { drawerOpen, toggleDrawer } = useAppContext()

  const { user } = useAuthContext()

  const controls = useAnimation()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  React.useEffect(() => {
    if (drawerOpen) {
      controls.start('open')
    } else {
      controls.start('closed')
    }
  }, [isTabletOrMobile, drawerOpen, controls])

  const variants: Variants = {
    open: {
      x: 0,
      width: 250,
      transition: { staggerChildren: 0.2, delayChildren: 0.1, stiffness: 80 }
    },
    closed: {
      x: isTabletOrMobile ? -250 : 0,
      width: isTabletOrMobile ? 250 : 64,
      transition: {
        stiffness: 80,
        staggerDirection: -1,
        staggerChildren: 0.2,
        delay: isTabletOrMobile ? 0.25 : 0
      }
    }
  }

  return (
    <>
      <MenuCont
        bg={bg}
        flexDir="column"
        animate={controls}
        variants={variants}
        alignItems="flex-start"
        justifyContent="flex-start"
        initial={{ width: drawerOpen ? 250 : 64 }}
      >
        <Flex
          pl="20px"
          width="100%"
          height="64px"
          alignItems="center"
          borderBottomWidth={1}
          justifyContent="flex-start"
          borderColor={borderColor}
        >
          <SideBarButton color={color} open={drawerOpen} onClick={toggleDrawer} />
          <Flex pr={2} flex={1} justifyContent="flex-end">
            <AnimatePresence>
              {drawerOpen && (
                <motion.img
                  width="75%"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  src={images.sovtechLogo}
                  style={{ alignSelf: 'flex-start' }}
                />
              )}
            </AnimatePresence>
          </Flex>
        </Flex>
        {user && (
          <MotionFlex
            py={4}
            pr={4}
            pl="18px"
            width="100%"
            align="center"
            justify="flex-start"
            borderBottomWidth={1}
            borderBottomColor={borderColor}
            style={{ whiteSpace: 'nowrap' }}
            whileHover={{
              backgroundColor: get(theme, `colors.${hoverColor}`, 'pink'),
              cursor: 'pointer'
            }}
          >
            <Avatar
              mr={4}
              name={
                (user?.role?.name === 'Client' ? user?.client?.companyName : user?.username) || ''
              }
              size="sm"
            />
            <AnimatePresence>
              {drawerOpen && (
                <Text
                  color={color}
                  fontWeight="bold"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0, pointerEvents: 'none' }}
                >
                  {(user?.role?.name === 'Client' ? user?.client?.companyName : user?.username) ||
                    ''}
                </Text>
              )}
            </AnimatePresence>
          </MotionFlex>
        )}
        {navItems.map((props) => (
          <SideBarItem
            color={color}
            key={props.title}
            hoverColor={hoverColor}
            accentColor={accentColor}
            tooltipColor={tooltipColor}
            tooltipBg={tooltipBg}
            {...props}
          />
        ))}
        <SideBarSupportItem
          color={color}
          hoverColor={hoverColor}
          accentColor={accentColor}
          tooltipColor={tooltipColor}
          tooltipBg={tooltipBg}
          to={'mailto:support@sovtech.com'}
          title="Support"
          icon={<HelpCircle size={20} color="white" className="sidebar-menu-icon" />}
          // {...props}
        />
        <Copyright
          color="gray.500"
          position="absolute"
          bottom="10px"
          fontSize="10pt"
          width="100%"
          textAlign="center"
        />
      </MenuCont>
      <RenderWrapper
        animate
        className="render-wrapper"
        pl={isTabletOrMobile ? 0 : drawerOpen ? '250px' : '64px'}
      >
        <Header />
        {children}
        {isTabletOrMobile && (
          <Overlay
            onClick={toggleDrawer}
            initial={{ opacity: 0 }}
            pointerEvents={drawerOpen ? 'auto' : 'none'}
            animate={drawerOpen ? { opacity: 1 } : { opacity: 0 }}
          />
        )}
      </RenderWrapper>
    </>
  )
}

export default SideBar

SideBar.defaultProps = {
  color: 'white',
  bg: 'gray.900',
  hoverColor: 'gray.800',
  borderColor: 'gray.700',
  accentColor: 'cyan.500'
}
