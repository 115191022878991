import { lazy } from 'react'
import { RouteProps } from 'react-router'
import QuoteBuilder from '../containers/QuoteBuilder'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const Dashboard = lazy(() => import('../containers/Dashboard'))
const Projects = lazy(() => import('../containers/Projects'))
const Project = lazy(() => import('../containers/Project'))
const ClientDetails = lazy(() => import('../containers/ClientDetails'))
const Clients = lazy(() => import('../containers/Clients'))
const Quotes = lazy(() => import('../containers/Quotes'))
const Login = lazy(() => import('../containers/Login'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const Onboarding = lazy(() => import('../containers/Onboarding'))
const Subscriptions = lazy(() => import('../containers/Subscriptions'))
const SubscriptionDetails = lazy(() => import('../containers/SubscriptionDetails'))
const AcceptInvite = lazy(() => import('../containers/AcceptInvite'))
const Billing = lazy(() => import('../containers/Billing'))
const Terms = lazy(() => import('../containers/Terms'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/auth/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/auth/projects',
    breadcrumb: 'Projects',
    component: Projects,
    title: 'Projects'
  },
  {
    exact: true,
    path: '/auth/clients',
    breadcrumb: 'Clients',
    component: Clients,
    title: 'Clients'
  },
  {
    exact: true,
    path: '/auth/clients/:id',
    breadcrumb: 'Client Details',
    component: ClientDetails,
    title: 'Client Details'
  },
  {
    exact: true,
    path: '/auth/projects/:id',
    breadcrumb: 'Project Details',
    component: Project,
    title: 'Project Details'
  },
  {
    exact: true,
    path: '/auth/quotes',
    breadcrumb: 'Quotes',
    component: Quotes,
    title: 'Quotes'
  },
  {
    exact: true,
    path: '/auth/quotebuilder/:id',
    breadcrumb: 'Quote Builder',
    component: QuoteBuilder,
    title: 'Quote Builder'
  },
  {
    exact: true,
    path: '/auth/subscriptions',
    breadcrumb: 'Subscriptions',
    component: Subscriptions,
    title: 'Subscriptions'
  },
  {
    exact: true,
    path: '/auth/subscriptions/:id',
    breadcrumb: 'Subscription Details',
    component: SubscriptionDetails,
    title: 'Subscription Details'
  },
  {
    exact: true,
    path: '/auth/organisation',
    breadcrumb: 'Organisation Details',
    component: ClientDetails,
    title: 'Organisation Details'
  },
  {
    exact: true,
    path: '/auth/billing',
    breadcrumb: 'Billing',
    component: Billing,
    title: 'Billing'
  },
  {
    exact: true,
    path: '/auth/terms',
    breadcrumb: 'Legal Terms',
    component: Terms,
    title: 'Legal Terms'
  }
  // {
  //   exact: true,
  //   path: '/auth/projects/:projectId/quote/:quoteId',
  //   breadcrumb: 'Quote Details',
  //   component: QuoteDetails,
  //   title: 'Quote Details'
  // },
  // {
  //   exact: true,
  //   path: '/auth/projects/:projectId/quote/:quoteId/configure',
  //   breadcrumb: 'Quote Configuration',
  //   component: Quote,
  //   title: 'Quote Configuration'
  // }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Login',
    path: '/login/:provider',
    component: Login
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Accept Invite',
    path: '/invite/:code',
    component: AcceptInvite
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    exact: true,
    title: 'Onboarding',
    path: '/onboarding',
    component: Onboarding
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
