import React from 'react'
import { Flex, Button, useToast, Text } from '@chakra-ui/core'
import { useUpdateQuoteMutation } from '../../../../generated/graphql'
import { ModalWrap } from '../../../../components'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants/index'
import { formatError } from '../../../../utils'
import { Formik, Form, FormikProps } from 'formik'
import { ConnectedFormGroup } from '../../../../components/FormElements'
import * as Yup from 'yup'

type EditQuoteModalProps = {
  onClose: any
  isOpen: any
  refetch: any
  quote: any
}

type InitialValues = {
  name: string | null | undefined
}

const EditQuoteFormValidation = Yup.object().shape({
  name: Yup.string().required('A name for the feature is required.')
})

const EditQuoteModal: React.FC<EditQuoteModalProps> = (props) => {
  const toast = useToast()

  const INITIAL_VALUES: InitialValues = {
    name: props.quote?.name || ''
  }

  const [updateQuote, { loading }] = useUpdateQuoteMutation({
    onCompleted: () => {
      toast({ description: 'Quote updated.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: () => {
      toast({ description: 'There was an error updating quote.', ...ERROR_TOAST })
    }
  })

  return (
    <ModalWrap
      scrollBehavior="inside"
      title="Edit Quote"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Formik
        validationSchema={EditQuoteFormValidation}
        initialValues={INITIAL_VALUES}
        onSubmit={async ({ name }, { setStatus }) => {
          setStatus(null)
          try {
            await updateQuote({
              variables: {
                id: props.quote?.id || '',
                data: {
                  name
                }
              }
            })

            props.refetch()
            props.onClose()
          } catch (error) {
            setStatus(formatError(error))
          }
        }}
      >
        {({ status, handleSubmit, values, errors, setFieldValue }: FormikProps<InitialValues>) => {
          return (
            <Form style={{ width: '100%' }}>
              <Flex flexDir="column" p={4}>
                <ConnectedFormGroup
                  name="name"
                  label="Name"
                  placeholder="Provide a friendly name for this quote."
                />
                {status && (
                  <Text textAlign="right" color="red.500">
                    {status}
                  </Text>
                )}
                <Button onClick={handleSubmit} variantColor="brand" isLoading={loading}>
                  Update Quote
                </Button>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </ModalWrap>
  )
}

export default EditQuoteModal
