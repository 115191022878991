export default {
  bg: require('../assets/images/core-bg.jpg'),
  sovtechLogo: require('../assets/images/sovtech-small.png'),
  sovtechLogoBlue: require('../assets/images/sovtech-small1.png'),
  sovtechLogoAlt: require('../assets/images/sovtech-alt.png'),
  404: require('../assets/images/404.svg'),
  noData: require('../assets/images/no-data.png'),
  createBro: require('../assets/images/create-bro.png'),
  signin: require('../assets/images/btn_google_signin_dark_normal_web@2x.png'),
  dmp: require('../assets/images/desktop-icon.png'),
  web: require('../assets/images/laptop-mobile-icon.png'),
  app: require('../assets/images/mobile-icon.png'),
  component: require('../assets/images/component-icon.png'),
  feature: require('../assets/images/feature-icon.png'),
  admin: require('../assets/images/admin.png')
}
