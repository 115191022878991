import React, { Suspense } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader } from '../components'
import SideBar from '../components/SideBar'
import PageNotFound from '../containers/PageNotFound'
import { useAuthContext } from '../context/AuthProvider'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'
import {
  ReportMoney,
  Stack,
  Briefcase,
  Gauge,
  FileInvoice,
  BuildingBank,
  Archive
} from 'tabler-icons-react'
import { Scale } from 'tabler-icons-react'

interface RouteType extends RouteProps {
  component: any
}

interface NavigationProps {}

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

const NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/dashboard`,
    title: 'Dashboard',
    icon: <Gauge size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/clients`,
    title: 'Clients',
    icon: <Briefcase size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/projects`,
    title: 'Projects',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <Stack size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/quotes`,
    title: 'Quotes',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <FileInvoice size={20} color="white" className="sidebar-menu-icon" />
  }
]

const CLIENT_NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/organisation`,
    title: 'Organisation',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <BuildingBank size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/projects`,
    title: 'Projects',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <Stack size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/subscriptions`,
    title: 'Subscriptions',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <ReportMoney size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/billing`,
    title: 'Billing',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <Archive size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/terms`,
    title: 'Legal Terms',
    // icon: <Trello size={20} color="white" className="sidebar-menu-icon" />
    icon: <Scale size={20} color="white" className="sidebar-menu-icon" />
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated } = useAuthContext()

  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Suspense fallback={<FillLoader color="black" />}>
            <Component {...rest} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)

const Navigation: React.FC<NavigationProps> = () => {
  const { user } = useAuthContext()

  const getNavItems = () => {
    if (user?.role?.name === 'Client') {
      return CLIENT_NAV_ITEMS
    } else return NAV_ITEMS
  }
  return (
    <Router>
      <Suspense fallback={<FillLoader />}>
        <Switch>
          {PUBLIC_ROUTES.map((route) => {
            return <PublicRoute key={route.path} {...route} />
          })}
          <Route
            path="/auth/quotebuilder"
            render={() => (
              <>
                {PRIVATE_ROUTES.map((route) => {
                  return <PrivateRoute key={route.path} {...route} />
                })}
              </>
            )}
          />
          <Route
            path="/auth"
            render={() => (
              <SideBar
                bg="sidebar"
                color="white"
                navItems={getNavItems()}
                hoverColor="gray.700"
                accentColor="brand.500"
              >
                {PRIVATE_ROUTES.map((route) => {
                  return <PrivateRoute key={route.path} {...route} />
                })}
              </SideBar>
            )}
          />
          <Route render={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Navigation
