import React from 'react'
import { Spinner } from '@chakra-ui/core'
import Card from '../Card'
import RevealFlex from '../RevealFlex/index'

const LoadingListHandler: React.FC = () => {
  return (
    <Card
      p={4}
      flex={1}
      width="100%"
      align="center"
      maxWidth="100%"
      justify="center"
      flexDirection="column"
    >
      <RevealFlex>
        <Spinner color="brand.300" />
      </RevealFlex>
    </Card>
  )
}

export default LoadingListHandler
