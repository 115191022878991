import React from 'react'
import {
  Flex,
  Button,
  Text,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Box,
  useToast,
  Icon
} from '@chakra-ui/core'
import { Formik, Form, FormikProps } from 'formik'
import { ConnectedFormGroup, ConnectedNumberInput } from '../../../../components/FormElements'
import * as Yup from 'yup'
import ConnectedTextarea from '../../../../components/FormElements/ConnectedTextArea'
import NodeComments from './nodeComments'
import FeatureCarousel from './featureCarousel'
import ConnectedSelect from '../../../../components/FormElements/ConnectedSelect'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../../constants'
import { formatError } from '../../../../utils'
import { useAuthContext } from '../../../../context/AuthProvider'
import _ from 'lodash'
import { Node, Edge, getConnectedEdges, isEdge } from 'react-flow-renderer'
import { Change, statusToInt } from '../..'
import { useUpdateQuoteMutation } from '../../../../generated/graphql'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useQuoteContext } from '../../../../context/QuoteProvider'

type DetailsDrawerProps = {
  removeElements: any
  setElements: any
  node: any
  elements: any
  onClose: any
  isOpen: any
  data: any
  refetch: any
}

type InitialValues = {
  name: string
  description: string
  type?: string
  hours?: string
}

const AddFeatureFormValidation = Yup.object().shape({
  name: Yup.string().required('A name for the feature is required.'),
  description: Yup.string().required('A description of the feature is required.'),
  hours: Yup.string().required('Hours of the feature is required.')
})

export const isIncluded = (element: any, list: any[]) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === element.id) {
      return true
    }
  }
  return false
}

//We need a more concurrent array filter than the traditional JS one. This is because Array.filter runs through an twice, first to evaluate the array element against the predicate and then a second time to compile values that pass the evaluation into a single array.

//We need ALL of the final array in an awaited response faster than this process provides it..

//Hence our own bespoke array filter that uses a map to provide the predicate evals to the Array.filter method already. We then return the array once all the Promises have been resolved.

export const asyncElementFilter = async (
  elements: (Node | Edge)[],
  predicate: (element: Node | Edge) => boolean
) =>
  await Promise.all(elements.map((element) => predicate(element))).then((results) =>
    elements.filter((_z, index) => results[index])
  )

const DetailsDrawer: React.FC<DetailsDrawerProps> = (props) => {
  const toast = useToast()
  const INITIAL_VALUES: InitialValues = {
    name: props.node.data?.name || '',
    description: props.node.data?.description || '',
    type: props.node.data?.type || 'default',
    hours: props.node.data?.featureTime || '0'
  }

  const params: any = useParams()
  const { user } = useAuthContext()
  const { isApproved, clientView } = useQuoteContext()

  const [updateQuote] = useUpdateQuoteMutation({
    onCompleted: () => {
      // toast({ description: 'Quote updated.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: (error) => {
      toast({ description: 'There was an error updating quote.', ...ERROR_TOAST })
      console.log(error)
    }
  })

  const handleDelete = async () => {
    try {
      if (props.node.type === 'platformNode') {
        //If node to be deleted is a platform node

        const children = props.elements.filter((element: Node | Edge) => {
          if (element.data?.platform) {
            if (element.data?.platform === props.node.id) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })

        const looseEnds = getConnectedEdges(
          children as Node[],
          (await asyncElementFilter(props.elements, isEdge)) as Edge[]
        )

        let removeTheseNodes = [props.node].concat(children)
        removeTheseNodes = removeTheseNodes.concat(looseEnds)

        props.removeElements(removeTheseNodes, props.elements)
      } else if (props.node.type === 'componentNode') {
        //If node to be deleted is a component node
        let removeTheseComponents = [props.node]

        props.setElements(async (els: any) => {
          const recur = (myId: any) => {
            const children = props.elements.filter((element: Node | Edge) => {
              if (element.data?.parent) {
                if (element.data?.parent === myId) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            })

            if (children.length > 0) {
              removeTheseComponents = removeTheseComponents.concat(children)
            }

            let grandchildren: any[] = []

            children.forEach((child: any) => {
              const newGrandChildren = props.elements.filter((element: Node | Edge) => {
                if (element.data?.parent) {
                  if (element.data?.parent === child.id) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  return false
                }
              })
              grandchildren = grandchildren.concat(newGrandChildren)
            })

            if (grandchildren.length > 0) {
              children.forEach((child: any) => recur(child.id))
            }
          }

          const childIndex = _.findIndex(els, {
            data: { parent: props.node.id }
          })
          if (childIndex > -1) {
            recur(props.node.id)
          }
          const parentIndex = _.findIndex(els, { id: props.node.data.parent })
          els[parentIndex].data.hours -= props.node.data.hours
          els[parentIndex].data.children = _.remove(
            els[parentIndex].data.children,
            (child: string) => child === props.node.id
          )

          const removedHours = props.node.data.hours

          const recur2 = (parent2: any) => {
            const parentIndex = _.findIndex(els, { id: parent2 })
            els[parentIndex].data.hours -= removedHours
            if (els[parentIndex].data.parent) {
              recur2(els[parentIndex].data.parent)
            }
          }
          if (els[parentIndex].data.parent) {
            recur2(els[parentIndex].data.parent)
          }

          const looseEnds = getConnectedEdges(
            removeTheseComponents as Node[],
            (await asyncElementFilter(props.elements, isEdge)) as Edge[]
          )

          //Adding the edges that connect the deleted elements to the array of elements to be removed
          removeTheseComponents = removeTheseComponents.concat(looseEnds)

          //updating the els array to exclude elements in the removeTheseComponents array

          const newEls = els.filter(
            (element: Node | Edge) => !isIncluded(element, removeTheseComponents)
          )

          //Find out the parent index in the updated elements array

          const parentIndex2 = _.findIndex(newEls, {
            id: props.node.data.parent
          })

          if (newEls[parentIndex2].type === 'platformNode') {
            //If the parent of the removed node is a platform, then there are no more children in this tree so the platform is ACCEPTED

            newEls[parentIndex2].data.status = 'ACCEPTED'
          } else {
            //If the parent node is a component node though, then a status evaluation must be ran on the updated element list

            const platformIndex = _.findIndex(newEls, {
              id: props.node.data.platform
            })

            const platformChildren = newEls.filter((element: Node | Edge) => {
              if (element.data?.platform) {
                if (element.data.platform === props.node.data.platform) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            }) as Node[]

            const minComponent = platformChildren.reduce((comp1, comp2) =>
              statusToInt(comp1.data.status) < statusToInt(comp2.data.status) ? comp1 : comp2
            )

            if (
              statusToInt(minComponent.data.status) !==
              statusToInt(newEls[platformIndex].data.status)
            ) {
              newEls[platformIndex].data.status = minComponent.data.status
            }
          }

          return newEls
        })
      } else {
        //If node to be deleted is a feature node
        props.setElements((els: any) => {
          const parentIndex = _.findIndex(els, { id: props.node.data.parent })
          const thisIndex = _.findIndex(els[parentIndex].data.features, {
            id: props.node.id
          })
          els[parentIndex].data.features.splice(thisIndex, 1)
          els[parentIndex].data.hours -= props.node.data.featureTime

          //recalc parent totals
          const recur = (parent2: any) => {
            const parentIndex = _.findIndex(els, { id: parent2 })
            els[parentIndex].data.hours -= props.node.data.featureTime
            if (els[parentIndex].data.parent) {
              recur(els[parentIndex].data.parent)
            }
          }
          if (els[parentIndex].data.parent) {
            recur(els[parentIndex].data.parent)
          }
          return els
        })
      }
      //Updating change log
      const changelist = props?.data?.quote?.changes || []
      const change: Change = {
        time: moment().format('DD/MM/YYYY, hh:mm A'),
        user: user?.username,
        description: `Node Deleted`
      }
      updateQuote({
        variables: {
          id: params.id,
          data: {
            changes: changelist.concat(change)
          }
        }
      })
      props.onClose()
    } catch (error) {
      toast({ description: `Error removing Node.`, ...ERROR_TOAST })
      console.log('error:', error)
    }
  }

  return (
    <Drawer size="lg" placement="right" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent overflowY="scroll">
        <Box p={4} display="flex" flexDirection="column">
          <FeatureCarousel featureParent={props?.node?.data?.template} />
          <Formik
            validationSchema={AddFeatureFormValidation}
            initialValues={INITIAL_VALUES}
            onSubmit={({ name, description, hours, type }, { setStatus }) => {
              try {
                //Edit node
                props.setElements((els: any) => {
                  if (props.node.type === 'platformNode' || props.node.type === 'componentNode') {
                    //Editing the platform and component nodes by finding their places in the elements array and directly changing the data values
                    const thisIndex = _.findIndex(els, { id: props.node.id })
                    els[thisIndex].data = {
                      ...els[thisIndex].data,
                      name,
                      description,
                      type
                    }
                  } else {
                    //editing feature nodes

                    //first by finding the parent components index
                    const parentIndex = _.findIndex(els, { id: props.node.data.parent })

                    //then by finding this feature's index in the component features array
                    const thisFeatIndex = _.findIndex(els[parentIndex].data.features, {
                      id: props.node.id
                    })
                    const editedHours = parseInt(hours + '')

                    //If there is a difference between the submitted and initial hours, then the difference is propagated to parent nodes
                    if (props.node.data.featureTime !== editedHours) {
                      const differenceInHrs = editedHours - props.node.data.featureTime

                      //Adding the difference in hours to the parent component
                      els[parentIndex].data.hours += differenceInHrs

                      //recusively add the difference to parents nodes
                      const recur = (parent2: any) => {
                        const parentIndex = _.findIndex(els, { id: parent2 })
                        els[parentIndex].data.hours += differenceInHrs
                        if (els[parentIndex].data.parent) {
                          recur(els[parentIndex].data.parent)
                        }
                      }
                      if (els[parentIndex].data.parent) {
                        recur(els[parentIndex].data.parent)
                      }
                    }

                    //the by editing the values of the array component
                    els[parentIndex].data.features[thisFeatIndex].data = {
                      ...els[parentIndex].data.features[thisFeatIndex].data,
                      name,
                      description,
                      type,
                      featureTime: editedHours
                    }
                  }
                  return els
                })
                //Updating change log
                const changelist = props?.data?.quote?.changes || []
                const change: Change = {
                  time: moment().format('DD/MM/YYYY, hh:mm A'),
                  user: user?.username,
                  description: `Node Details updated. \nFrom: name - ${
                    INITIAL_VALUES.name
                  }, description - ${INITIAL_VALUES.description}, hours - ${
                    INITIAL_VALUES.hours || 'N/A'
                  }, type - ${
                    INITIAL_VALUES.type || 'N/A'
                  }. \nTo: name - ${name}, description - ${description}, hours - ${
                    hours || 'N/A'
                  }, type - ${type || 'N/A'}.
                  `,
                  type: props.node.type,
                  typeID: props.node.id
                }
                updateQuote({
                  variables: {
                    id: params.id,
                    data: {
                      changes: changelist.concat(change)
                    }
                  }
                })

                toast({ description: `Node Updated.`, ...SUCCESS_TOAST })
                props.onClose()
              } catch (error) {
                setStatus(formatError(error))
                toast({ description: `Error updating Node`, ...ERROR_TOAST })
              }
            }}
          >
            {({ status, isSubmitting, handleSubmit }: FormikProps<InitialValues>) => {
              return (
                <Form style={{ width: '100%' }}>
                  <Flex flexDir="column" p={4}>
                    {user?.role?.name === 'Client' ? (
                      <>
                        <Box>
                          <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl" mb={1}>
                            Name:
                          </Text>
                          <Text fontSize="sm" fontWeight="normal" mb={3}>
                            {props.node.data.name}
                          </Text>
                          <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl" mb={1}>
                            Description:
                          </Text>
                          <Text fontSize="sm" fontWeight="normal" mb={3}>
                            {props.node.data.description}
                          </Text>
                          {props.node.data.type && (
                            <>
                              <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl">
                                Type:
                              </Text>
                              <Text fontSize="sm" fontWeight="normal" mb={3}>
                                {props.node.data.type}
                              </Text>
                            </>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <ConnectedFormGroup
                          name="name"
                          label="Name"
                          placeholder="What should we call this node?"
                        />
                        <ConnectedTextarea
                          name="description"
                          label="Description"
                          placeholder="Tell us a bit about what this node does?"
                        />
                        {props.node.data.type && !clientView ? (
                          <ConnectedSelect
                            name="type"
                            label="Type"
                            options={[
                              { label: 'DMP', value: 'dmp' },
                              { label: 'Web App', value: 'web' },
                              { label: 'Mobile App', value: 'app' }
                            ]}
                          />
                        ) : (
                          <></>
                        )}
                        {!clientView && (
                          <>
                            {props.node.data.featureTime ? (
                              <ConnectedNumberInput
                                isDisabled={user?.role?.name === 'Architect'}
                                name="hours"
                                label="Estimate"
                                unit="Hours"
                              />
                            ) : (
                              <Flex justifyContent="space-between" marginBottom={2}>
                                <Text color="brand.500">
                                  Total Hours: {props.node.data?.hours || 0}
                                </Text>
                              </Flex>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {status && (
                      <Text textAlign="right" color="red.500">
                        {status}
                      </Text>
                    )}
                    {isApproved && (
                      <Text fontSize="sm" color="gray.600" mb={4}>
                        <Icon name="info" mr={1} size="15px" />
                        This quote has been approved and cannot have its nodes edited or commented
                        on.
                      </Text>
                    )}
                    {user?.role?.name !== 'Client' && (
                      <Button
                        onClick={handleSubmit}
                        variantColor="brand"
                        leftIcon="plus-square"
                        isLoading={isSubmitting}
                        marginTop={2}
                        isDisabled={isApproved || user?.role?.name === 'Client'}
                      >
                        Update Node
                      </Button>
                    )}
                    {props.node.type !== 'platformNode' ? (
                      <>
                        <Divider marginBottom={3} />
                        <NodeComments
                          setElements={props.setElements}
                          comments={props.node.data.comments.filter((c: any) => !c.deleted)}
                          node={props.node}
                          data={props.data}
                          refetch={props.refetch}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <Divider marginBottom={3} />
                    {user?.role?.name !== 'Client' && (
                      <Button
                        onClick={handleDelete}
                        color="red.500"
                        leftIcon="delete"
                        marginTop={2}
                        isDisabled={isApproved || user?.role?.name === 'Client'}
                      >
                        Delete Node
                      </Button>
                    )}
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </DrawerContent>
    </Drawer>
  )
}

export default DetailsDrawer
