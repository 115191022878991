import React from 'react'
import { Flex, Button, Divider, useToast } from '@chakra-ui/core'
import { ModalWrap } from '../../../../components'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'
import { ConnectedFormGroup } from '../../../../components/FormElements'
import { formatError } from '../../../../utils'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'
import { useCreateTemplateMutation } from '../../../../generated/graphql'

type MakeTemplateModalProps = {
  isOpen: any
  onClose: any
  data: any
  refetch: any
}

type InitialValues = {
  name: string
}

const MakeTemplateModal: React.FC<MakeTemplateModalProps> = (props) => {
  const toast = useToast()

  const [createTemplateMutation, { loading: createTemplateLoading }] = useCreateTemplateMutation({
    onCompleted: () => {
      toast({ description: 'New Template added.', ...SUCCESS_TOAST })
    },
    onError: () => {
      toast({ description: 'Error adding Template', ...ERROR_TOAST })
    }
  })

  return (
    <ModalWrap title="Make a Template" isOpen={props.isOpen} onClose={props.onClose} size="md">
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string().required('A name for the feature is required.')
        })}
        initialValues={{
          name: ''
        }}
        onSubmit={async ({ name }, { setStatus }) => {
          setStatus(null)
          try {
            await createTemplateMutation({
              variables: {
                data: {
                  name,
                  flow: props.data.quote?.nodes,
                  hours: props.data.quote?.hours
                }
              }
            })
            props.onClose()
          } catch (error) {
            setStatus(formatError(error))
          }
        }}
      >
        {({ status, handleSubmit }: FormikProps<InitialValues>) => {
          return (
            <Form style={{ width: '100%' }}>
              <Flex flexDir="column" p={4}>
                <ConnectedFormGroup
                  name="name"
                  label="Name"
                  placeholder={`What should we call this Template?`}
                />

                <Divider marginBottom={3} />
                <Flex justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      props.onClose()
                    }}
                    marginX={2}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={createTemplateLoading}
                    onClick={handleSubmit}
                    variantColor="brand"
                    marginX={2}
                    paddingX={6}
                  >
                    Add Template
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </ModalWrap>
  )
}

export default MakeTemplateModal
