import React from 'react'
import { Flex, Button, Text, Divider, useToast, Box } from '@chakra-ui/core'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../../constants'
import { ModalWrap } from '../../../../components'
import FeatureCarousel from './featureCarousel'
import { useQuoteContext } from '../../../../context/QuoteProvider'

type StandardFeatureViewProps = {
  addStdFeature: any
  addCustFeature: any
  // selected: any
  onClose: any
  isOpen: any
  openFeat: any
  // parent: string
}

const StandardFeatureView: React.FC<StandardFeatureViewProps> = (props) => {
  const toast = useToast()
  const { parent, selectedFeature } = useQuoteContext()
  return (
    <ModalWrap title="Feature Details" isOpen={props.isOpen} onClose={props.onClose}>
      <Flex flexDir="column" p={6} pb={2}>
        <Box>
          <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl" mb={1}>
            Feature Name:
          </Text>
          <Text fontSize="sm" fontWeight="normal" mb={3}>
            {selectedFeature.name}
          </Text>
          <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl" mb={1}>
            Feature Description:
          </Text>
          <Text fontSize="sm" fontWeight="normal" mb={3}>
            {selectedFeature.description}
          </Text>
          <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl">
            Hours:
          </Text>
          <Text fontSize="sm" fontWeight="normal" mb={3}>
            {selectedFeature.hours} Hours
          </Text>
        </Box>
        <Text as="h2" fontWeight="bold" lineHeight={1.2} fontSize="xl" mb={2}>
          Screenshots:
        </Text>
        <FeatureCarousel featureParent={selectedFeature.template} />
        <Divider mb={2} mt={4} />
      </Flex>
      <Flex mb={3} ml={4} justifyContent="flex-end">
        <Button
          onClick={() => {
            props.onClose()
            props.openFeat()
          }}
          marginX={2}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            try {
              if (selectedFeature.custom === true) {
                props.addCustFeature(
                  selectedFeature.name,
                  selectedFeature.description,
                  {},
                  parseInt(selectedFeature.hours),
                  parent
                )
              } else {
                props.addStdFeature(
                  selectedFeature.name,
                  selectedFeature.description,
                  parseInt(selectedFeature.hours),
                  parent,
                  selectedFeature.template,
                  selectedFeature.integration
                )
              }
              toast({ description: `New Feature added.`, ...SUCCESS_TOAST })
              props.onClose()
            } catch (error) {
              toast({ description: `Error adding Feature.`, ...ERROR_TOAST })
            }
          }}
          variantColor="brand"
          marginX={2}
          paddingX={6}
        >
          Add Feature
        </Button>
      </Flex>
    </ModalWrap>
  )
}

export default StandardFeatureView
