import React from 'react'
import { Image } from '@chakra-ui/core'
import { useFeatureTemplateQuery } from '../../../../generated/graphql'
import images from '../../../../theme/images'

type FeatureCarouselProps = {
  featureThumbnail?: any
  featureParent?: any
}

const FeatureCarousel: React.FC<FeatureCarouselProps> = (props) => {
  const { data } = useFeatureTemplateQuery({
    variables: { id: props.featureParent }
  })

  //TO-DO: Re-add carousel with a solution to the Mutation Observer bug caused by nuka-carousel, or using another vetted carousel package

  return (
    <>
      <Image
        height="150px"
        objectFit="contain"
        src={data?.featureTemplate?.thumbnail?.url || props.featureThumbnail}
        fallbackSrc={images.sovtechLogoAlt}
      />
    </>
  )
}

export default FeatureCarousel
