import React from 'react'
import { Flex, Button, Text, Divider, useToast } from '@chakra-ui/core'
import { Formik, Form, FormikProps } from 'formik'
import {
  ConnectedCheckbox,
  ConnectedFileUploader,
  ConnectedFormGroup
} from '../../../../components/FormElements'
import ConnectedTextarea from '../../../../components/FormElements/ConnectedTextArea'
import { AddNodeFormValidation } from '../../index'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../../../constants'
import { formatError } from '../../../../utils'
import { ModalWrap } from '../../../../components'
import ConnectedSelect from '../../../../components/FormElements/ConnectedSelect'
import { useCreateFeatureTemplateMutation } from '../../../../generated/graphql'

type CustomFeatureModalProps = {
  addCustFeature: any
  onClose: any
  isOpen: any
  openFeat: any
  parent: string
  refetch: any
  loading: boolean
}

type InitialValues = {
  name: string
  preserve: boolean
  description: string
  type: string
  attachments: object
  hours: number
}

const CustomFeatureModal: React.FC<CustomFeatureModalProps> = (props) => {
  const toast = useToast()

  const [
    createFeatureTemplateMutation,
    { loading: createFeatureTemplateLoading }
  ] = useCreateFeatureTemplateMutation({
    onCompleted: () => {
      toast({ description: 'Custom Feature Saved!', ...SUCCESS_TOAST })
    },
    onError: () => {
      toast({ description: 'There was an error updating quote.', ...ERROR_TOAST })
    }
  })

  return (
    <ModalWrap title="New Custom Feature Node" isOpen={props.isOpen} onClose={props.onClose}>
      <Formik
        validationSchema={AddNodeFormValidation}
        initialValues={{
          name: '',
          description: '',
          preserve: false,
          type: '',
          attachments: {},
          hours: 30
        }}
        onSubmit={async ({ name, description, attachments, hours, preserve }, { setStatus }) => {
          setStatus(null)
          try {
            if (preserve) {
              await createFeatureTemplateMutation({
                variables: {
                  data: {
                    name,
                    description,
                    hours,
                    custom: true
                  }
                }
              })
              props.refetch()
            }
            props.addCustFeature(name, description, attachments, hours, props.parent)
            toast({ description: 'New Custom Feature added.', ...SUCCESS_TOAST })
            props.onClose()
          } catch (error) {
            toast({ description: `Error adding Custom Feature.`, ...ERROR_TOAST })
            setStatus(formatError(error))
          }
        }}
      >
        {({ status, handleSubmit }: FormikProps<InitialValues>) => {
          return (
            <Form style={{ width: '100%' }}>
              <Flex flexDir="column" p={4}>
                <ConnectedFormGroup
                  name="name"
                  label="Name"
                  placeholder={`What should we call this Feature?`}
                />
                <ConnectedTextarea
                  name="description"
                  label="Description"
                  placeholder={`Tell us a bit about what this Feature does?`}
                />
                <ConnectedFileUploader
                  name="attachments"
                  label="Attachments"
                  placeholder="Upload any example files here"
                />
                <ConnectedSelect
                  name="hours"
                  label="Which package would you like?"
                  options={[
                    { label: '30 Hours', value: 30 },
                    { label: '60 Hours', value: 60 },
                    { label: '90 Hours', value: 90 }
                  ]}
                />
                <ConnectedCheckbox
                  isDisabled={props.loading}
                  name="preserve"
                  label="Preserve Custom Feature"
                />
                {status && (
                  <Text textAlign="right" color="red.500">
                    {status}
                  </Text>
                )}
                <Divider marginBottom={3} />
                <Flex justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      props.onClose()
                      props.openFeat()
                    }}
                    marginX={2}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={createFeatureTemplateLoading}
                    onClick={handleSubmit}
                    variantColor="brand"
                    marginX={2}
                    paddingX={6}
                  >
                    Add Custom Feature
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </ModalWrap>
  )
}

export default CustomFeatureModal
