import React from 'react'
import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Box,
  DrawerHeader,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  DrawerBody,
  Link,
  Icon,
  Divider
} from '@chakra-ui/core'
import { H5 } from '../../../../typography'
import { useZoomPanHelper } from 'react-flow-renderer'
import _ from 'lodash'

type HistoryDrawerProps = {
  onClose: any
  isOpen: any
  data: any
}

export const newlineText = (text: string) => {
  const newText = text.split('\n').map((str) => <p>{str}</p>)
  return newText
}

const HistoryDrawer: React.FC<HistoryDrawerProps> = (props) => {
  const { setCenter } = useZoomPanHelper()
  // checking whether a node is deleted so as to disble the link to it
  const isDeleted = (change: any) => {
    let thisIndex = -1
    if (change.type === 'featureNode') {
      //Because features are within component nodes, we'll search component nodes to check if the feature can be found within the component and then flag it's index by changing thisIndex
      props.data.quote.nodes.forEach((node: any) => {
        if (node.type === 'componentNode') {
          const featIndex = _.findIndex(node.data.features, {
            id: change.typeID
          })
          if (featIndex !== -1) {
            thisIndex = featIndex
          }
        }
      })
    } else {
      thisIndex = _.findIndex(props.data.quote.nodes, {
        id: change.typeID
      })
    }
    if (thisIndex === -1) {
      return true
    } else {
      return false
    }
  }

  const handleClick = (change: any) => {
    //find from data.nodes the id and get position to pan to that node
    if (change.type === 'featureNode') {
      let thisIndex = -1
      let changedNode: any
      //Find the component the feature is in and then use that as the reference point on the canvas since features do no have a position property
      props.data.quote.nodes.forEach((node: any) => {
        if (node.type === 'componentNode') {
          thisIndex = _.findIndex(node.data.features, {
            id: change.typeID
          })
          if (thisIndex !== -1) {
            changedNode = node
          }
        }
      })
      //Below is the logic that pans to a clicked node link using the setCenter zoom helper
      //Y-position on the pan is set by adding the length of the component node to the number of feature node lengths there are up until the feature in focus (at thisIndex)
      if (changedNode) {
        setCenter(
          changedNode.position.x + 600,
          changedNode.position.y + 220 + 210 * (thisIndex + 1),
          0.8
        )
      }
    } else {
      //Find the node who's ID has been clicked and use its position to pan to it
      const thisIndex = _.findIndex(props.data.quote.nodes, {
        id: change.typeID
      })
      const changedNode = props.data.quote.nodes[thisIndex]
      //The added values to the x and y position are to put the node in the middle of the part of the screen in focus
      if (changedNode) {
        setCenter(changedNode.position.x + 600, changedNode.position.y + 220, 0.8)
      }
    }
  }

  //Slicing the changes array at position 0 creates a shallow copy and reversing it will show the latest changes added first

  return (
    <Drawer size="md" placement="right" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent overflowY="scroll">
        <DrawerHeader>Version History</DrawerHeader>
        <DrawerBody>
          <Box display="flex" flexDirection="column">
            {props?.data?.quote?.changes ? (
              <Accordion allowToggle>
                {props?.data?.quote?.changes
                  .slice(0)
                  .reverse()
                  .map((change: any) => (
                    <AccordionItem>
                      <H5>
                        <AccordionHeader _expanded={{ bg: 'brand.500', color: 'white' }}>
                          <Box flex="1" textAlign="left">
                            {change.time ? (
                              <>
                                {change.time} <br />
                              </>
                            ) : (
                              <></>
                            )}
                            {change.user ? <>{change.user}</> : <></>}
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                      </H5>
                      <AccordionPanel pb={4}>
                        {change.description ? (
                          <>Description: {newlineText(change.description)}</>
                        ) : (
                          ''
                        )}
                        {change.type ? `Type: ${change.type}` : ''}
                        {change.typeID || change.typeID === 0 ? (
                          <>
                            <Text>
                              Type ID:{' '}
                              {change.type !== 'comment' ? (
                                <Link
                                  href="#"
                                  color="brand.500"
                                  onClick={() => handleClick(change)}
                                  isDisabled={isDeleted(change)}
                                >
                                  {change.typeID}
                                </Link>
                              ) : (
                                change.typeID
                              )}
                            </Text>
                          </>
                        ) : (
                          ''
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
              </Accordion>
            ) : (
              <>
                <Divider />
                <Text fontSize="sm" color="gray.600" mt={4}>
                  <Icon name="info" mr={1} size="15px" />
                  Any changes made to your quote will appear here
                </Text>
              </>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default HistoryDrawer
