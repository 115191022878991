import React from 'react'
import { Button, Flex, useToast } from '@chakra-ui/core'
import { ModalWrap, Stepper } from '../../../components'
import {
  UploadFile,
  useCreateProjectMutation,
  useCreateClientMutation,
  useCreateCustomQuoteMutation,
  useTemplatesQuery
} from '../../../generated/graphql'
import {
  countryOptions,
  ERROR_TOAST,
  industryOptions,
  SUCCESS_TOAST
} from '../../../constants/index'
import { H3, Text } from '../../../typography'
import * as Yup from 'yup'
import { Formik, FormikProps, Form } from 'formik'
import { formatError } from '../../../utils'
import {
  ConnectedCheckbox,
  ConnectedFormGroup,
  ConnectedSearchableSelect
} from '../../../components/FormElements'
import CardFooter from '../../../components/Card/CardFooter'
import { useAuthContext } from '../../../context/AuthProvider'
import ConnectedTextarea from '../../../components/FormElements/ConnectedTextArea'
import ConnectedSelect from '../../../components/FormElements/ConnectedSelect'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { get } from 'lodash'

type QuickCreateModalProps = {
  isOpen: boolean
  onClose: any
}

/**
 * NOTE: in order for this to work you'll need a
 * logo single media type in strapi
 */

type InitialValues = {
  companyName: string
  clientWebsite: string
  clientLogo: string
  clientIndustry: any
  clientCountry: any
  projectName: string
  projectDescription: string
  projectLogo: UploadFile | undefined
  partner: boolean
  quoteName: string
  platforms: Array<{
    id: string
    name: string
    platform: string
  }>
  template: string
}

const QuickCreateValidation = Yup.object().shape({
  companyName: Yup.string().required('A company name is required'),
  clientIndustry: Yup.string().required('A client industry is required'),
  clientCountry: Yup.string().required('A client country is required'),
  projectName: Yup.string().required('A project name is required'),
  projectDescription: Yup.string()
    .required('A project description is required')
    .max(255, 'The description must be no longer than 255 characters'),
  quoteName: Yup.string().required('A quote name is required')
})

const QuickCreateModal: React.FC<QuickCreateModalProps> = (props) => {
  const { user } = useAuthContext()
  const { push } = useHistory()
  const toast = useToast()
  const [step, setStep] = useState<number>(0)

  const [createProject] = useCreateProjectMutation({
    onCompleted: () => {
      toast({ description: 'Project created.', ...SUCCESS_TOAST })
    },
    onError: () => {
      toast({ description: 'There was an error creating project.', ...ERROR_TOAST })
    }
  })

  const [createClient] = useCreateClientMutation({
    onCompleted: () => {
      toast({ description: 'Client created.', ...SUCCESS_TOAST })
    },
    onError: () => {
      toast({ description: 'There was an error creating Client.', ...ERROR_TOAST })
    }
  })

  const [createCustomQuote] = useCreateCustomQuoteMutation({
    onCompleted: () => {
      toast({ description: 'Quote created.', ...SUCCESS_TOAST })
      props.onClose()
    },
    onError: () => {
      toast({ description: 'There was an error creating quote.', ...ERROR_TOAST })
      props.onClose()
    }
  })

  const { data, loading } = useTemplatesQuery({
    notifyOnNetworkStatusChange: true
  })

  const INITIAL_VALUES: InitialValues = {
    companyName: '',
    clientWebsite: '',
    clientLogo: '',
    clientIndustry: null,
    clientCountry: null,
    projectName: '',
    projectDescription: '',
    projectLogo: undefined,
    partner: false,
    quoteName: '',
    platforms: [],
    template: ''
  }

  const templateOptions = () => {
    const templateList =
      data?.templates?.map((template) => ({
        label: template?.name || '',
        value: template?.id || ''
      })) || []
    return templateList
  }

  return (
    <ModalWrap title="Quick Create" isOpen={props.isOpen} onClose={props.onClose}>
      <Formik
        validationSchema={QuickCreateValidation}
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          setSubmitting(true)
          setStatus(null)
          try {
            const newClient = await createClient({
              variables: {
                data: {
                  companyName: values.companyName,
                  industry: values.clientIndustry.value,
                  country: values.clientCountry.value
                }
              }
            })

            const newProject = await createProject({
              variables: {
                data: {
                  client: newClient.data?.createClient?.client?.id,
                  logo: values.projectLogo?.id,
                  name: values.projectName,
                  description: values.projectDescription,
                  rep: user?.id,
                  partner: values.partner
                }
              }
            })

            const project = get(newProject.data?.createProject?.project, 'id')

            // @ts-ignore
            const newQuote = await createCustomQuote({
              variables: {
                data: {
                  // @ts-ignore
                  project,
                  name: values.quoteName,
                  platforms: [],
                  template: values.template
                }
              }
            })
            setSubmitting(false)
            setStep(0)
            push(`/auth/quotebuilder/${newQuote?.data?.createCustomQuote?.id}`)
          } catch (error) {
            setStatus(formatError(error))
          }
        }}
      >
        {({
          status,
          values,
          isSubmitting,
          handleSubmit,
          validateForm,
          errors
        }: FormikProps<InitialValues>) => {
          return (
            <Form style={{ width: '100%' }}>
              <Stepper activeStep={step}>
                <Flex px={4} direction="column">
                  <H3 mb={3}>Step 1: Add a Client</H3>
                  <ConnectedFormGroup
                    name="companyName"
                    label="Company Name"
                    placeholder="What is the name of the client?"
                  />
                  <ConnectedSearchableSelect
                    name="clientIndustry"
                    options={industryOptions}
                    label="Industry"
                    placeholder="Select an Industry"
                    value={values.clientIndustry}
                  />
                  <ConnectedSearchableSelect
                    name="clientCountry"
                    options={countryOptions}
                    label="Country"
                    placeholder="Select a Country"
                    value={values.clientCountry}
                  />
                  <CardFooter alignItems="flex-end">
                    <Flex>
                      <Button
                        mr={4}
                        variant="ghost"
                        variantColor="gray"
                        onClick={props.onClose}
                        size="sm"
                      >
                        Cancel
                      </Button>
                      <Button variantColor="brand" onClick={() => setStep(1)} size="sm">
                        Next
                      </Button>
                    </Flex>
                  </CardFooter>
                </Flex>
                <Flex px={4} direction="column">
                  <H3 mb={3}>Step 2: Start a Project</H3>
                  <ConnectedSelect
                    name="client"
                    label="Client Name"
                    placeholder={values.companyName}
                    options={[{ label: values.companyName, value: null }]}
                    isDisabled
                  />
                  <ConnectedCheckbox name="partner" label="Partner" />
                  <ConnectedFormGroup
                    name="projectName"
                    label="Project Name"
                    placeholder="What should we call this project?"
                  />
                  <ConnectedTextarea
                    name="projectDescription"
                    label="North Star (Objective)"
                    placeholder="Explain what the client is trying to acheive in one/two sentences."
                  />
                  <CardFooter alignItems="flex-end">
                    <Flex>
                      <Button
                        size="sm"
                        mr={4}
                        variant="ghost"
                        variantColor="gray"
                        onClick={() => setStep(0)}
                      >
                        Previous
                      </Button>
                      <Button
                        size="sm"
                        variantColor="brand"
                        onClick={() => {
                          validateForm()
                          setStep(2)
                        }}
                      >
                        Next
                      </Button>
                    </Flex>
                  </CardFooter>
                </Flex>
                <Flex px={4} direction="column">
                  <H3 mb={3}>Step 3: Build a Quote</H3>
                  <ConnectedFormGroup
                    name="quoteName"
                    label="Name"
                    placeholder="Provide a friendly name for this quote"
                  />
                  <ConnectedSelect
                    name="template"
                    label="Template"
                    placeholder="Select A Template"
                    options={templateOptions()}
                    isDisabled={loading}
                  />
                  {status && (
                    <Text textAlign="right" color="red.500">
                      {status}
                    </Text>
                  )}
                  <CardFooter alignItems="flex-end">
                    <Flex>
                      <Button
                        mr={4}
                        variant="ghost"
                        variantColor="gray"
                        onClick={() => setStep(1)}
                        size="sm"
                      >
                        Previous
                      </Button>
                      <Button
                        size="sm"
                        variantColor="brand"
                        isLoading={isSubmitting}
                        onClick={async () => {
                          await handleSubmit()
                          //If errors are found and the submit fails, do the following..
                          if (Object.keys(errors).length > 0) {
                            toast({ description: 'Fill in all fields correctly', ...ERROR_TOAST })
                            setStep(0)
                            errors = {}
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </Flex>
                  </CardFooter>
                </Flex>
              </Stepper>
            </Form>
          )
        }}
      </Formik>
    </ModalWrap>
  )
}

export default QuickCreateModal
