import { Button, Flex, Divider, Text, useToast, Icon } from '@chakra-ui/core'
import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import ReactFlow, {
  isNode,
  ReactFlowProvider,
  Background,
  MiniMap,
  Controls,
  addEdge,
  updateEdge,
  removeElements,
  useStoreState,
  BackgroundVariant,
  OnLoadParams
} from 'react-flow-renderer'
import { useLocation, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { FillLoader, ModalWrap } from '../../components'
import { Enum_Quote_Status, useQuoteQuery, useUpdateQuoteMutation } from '../../generated/graphql'
import { formatError } from '../../utils'
import BuilderHeader from './BuilderHeader'
import PlatformNode from './Nodes/PlatformNode'
import ComponentNode, { ComponentNodeProps } from './Nodes/ComponentNode'
import { theme } from '../../theme'
import DetailsDrawer from './Nodes/Modals/detailsDrawer'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { ConnectedFormGroup } from '../../components/FormElements'
import ConnectedTextarea from '../../components/FormElements/ConnectedTextArea'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../constants'
import ConnectedSelect from '../../components/FormElements/ConnectedSelect'
import ComponentModal from './Nodes/Modals/newComponentModal'
import FeatureModal from './Nodes/Modals/newFeatureModal'
import StandardFeatureView from './Nodes/Modals/stdFeatureView'
import { useQuoteContext } from '../../context/QuoteProvider'
import ApprovalDrawer from './Nodes/Modals/quoteApprovalDrawer'
import _ from 'lodash'
import MakeTemplateModal from './Nodes/Modals/makeTemplateModal'
import moment from 'moment'
import { useAuthContext } from '../../context/AuthProvider'
import { FeatureNodeProps } from './Nodes/FeatureNode'
import EditQuoteModal from './Nodes/Modals/editQuoteModal'
import HistoryDrawer from './Nodes/Modals/historyDrawer'
import { useAppContext } from '../../context/AppProvider'

type QuoteBuilderProps = {}

export type Change = {
  time: string
  user: string | undefined
  description: string
  type?: string
  typeID?: string
}

//On click of the typeID link text find el of that ID and setcenter(node.position.x, node.position.y, 1.85)

export type Point = {
  x: number
  y: number
}

export type Node = {
  id: string
  type: string | undefined
  position: Point | undefined
  data: object
  style: object
}

type Edge = {
  id: string
  type: string
  source: string
  target: string
  animated: boolean
  label: string
  arrowHeadType: string
}

type InitialValues = {
  name: string
  description: string
  type: string
  attachments: object
  hours: number
}

export const AddNodeFormValidation = Yup.object().shape({
  name: Yup.string().required('A name for the feature is required.'),
  description: Yup.string().required('A description of the feature is required.')
})

const nodeTypes = {
  platformNode: PlatformNode,
  componentNode: ComponentNode
}

export const BuilderCanvas = styled.div`
  background-color: ${theme.colors.gray[100]};
  padding-top: 4rem;
  height: 100vh;
  max-width: 100%;
`
//set a state var with this and change it to mock mutations

export const isValidConnection = (connection: any) => {
  switch (connection.source[0]) {
    case 'P':
      return connection.target[0] === 'C'
    case 'C':
      return connection.target[0] === 'C' || connection.target[0] === 'F'

    default:
      return false
  }
}

export const statusToInt = (status: string) => {
  switch (status) {
    case 'NEEDS REVIEW':
      return 1
    case 'IN REVIEW':
      return 2
    case 'ACCEPTED':
      return 3
    default:
      return 0
  }
}

export const nodeColor = (node: any) => {
  switch (node.type) {
    case 'input':
      return 'red'
    case 'default':
      return 'black'
    case 'output':
      return 'rgb(0,0,255)'
    case 'platformNode':
      return theme.colors.purple[300]
    case 'componentNode':
      return theme.colors.green[400]
    default:
      return '#eee'
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const QuoteBuilder: React.FC<QuoteBuilderProps> = (props) => {
  const query: any = useQuery()
  const params: any = useParams()
  const { drawerOpen, toggleDrawer } = useAppContext()
  const { user: thisUser } = useAuthContext()
  const toast = useToast()
  const [nodes, setNodes] = useState<any[]>([])

  const [rfInstance, setRfInstance] = useState<OnLoadParams<any>>()

  const {
    isOpenEditQuoteModal,
    onCloseEditQuoteModal,
    isOpenComponentModal,
    onOpenComponentModal,
    onCloseComponentModal,
    isOpenFeatureModal,
    onOpenFeatureModal,
    onCloseFeatureModal,
    onOpenCustomFeature,
    isOpenStdFeature,
    onOpenStdFeature,
    onCloseStdFeature,
    isOpenDetailsDrawer,
    onOpenDetailsDrawer,
    onCloseDetailsDrawer,
    isOpenPlatformModal,
    onOpenPlatformModal,
    onClosePlatformModal,
    selectedNode,
    setSelectedFeature,
    parent,
    isPlatButton,
    isOpenApprovalDrawer,
    onCloseApprovalDrawer,
    isOpenHistoryDrawer,
    onCloseHistoryDrawer,
    isOpenMakeTemplateModal,
    onCloseMakeTemplateModal,
    changedStatus,
    setChangedStatus,
    setClientView,
    allPlatforms,
    setAllPlatforms,
    setApprovalReady,
    isApproved,
    setIsApproved
  } = useQuoteContext()
  const { user } = useAuthContext()

  if (drawerOpen) toggleDrawer()

  React.useEffect(() => {
    const client = query.get('view') === 'client'
    setClientView(client)
  }, [query, setClientView])

  const { data, loading, refetch } = useQuoteQuery({
    variables: { id: params.id },
    fetchPolicy: 'no-cache'
  })

  const [updateQuote] = useUpdateQuoteMutation({
    onCompleted: () => {
      // toast({ description: 'Quote updated.', ...SUCCESS_TOAST })
      refetch()
    },
    onError: (error) => {
      toast({ description: 'There was an error updating quote.', ...ERROR_TOAST })
      console.error(error)
    }
  })

  const elements = data?.quote?.nodes

  const hoursCalc = (nodes: any) => {
    const platforms = nodes?.filter((n: any) => n.type === 'platformNode')
    const hours = platforms?.reduce((acc: number, curr: any) => acc + curr.data.hours, 0)
    return hours
  }

  const setElements = async (cb: any) => {
    const updated = await cb(data?.quote?.nodes)
    const hours = hoursCalc(updated)
    updateQuote({ variables: { id: params.id, data: { nodes: updated, hours } } })
  }

  const onElementsRemove = (elementsToRemove: any) =>
    setElements((els: any) => removeElements(elementsToRemove, els))
  const onConnect = (params: any) => {
    //adding arrowhead to edge and define id
    const edge = {
      ...params,
      arrowHeadType: 'arrowclosed'
    }
    setElements((els: any) => addEdge(edge, els))
  }
  const onEdgeUpdate = (oldEdge: any, newConnection: any) =>
    setElements((els: any) => updateEdge(oldEdge, newConnection, els))

  const NodesDimensions = () => {
    const newNodes = useStoreState((state) => state.nodes)

    setNodes(newNodes)

    return null
  }

  //A useEffect that populates the all platforms state var when the quote is updated

  React.useEffect(() => {
    const plats = data?.quote?.nodes.filter((el: any) => el.type === 'platformNode')
    setAllPlatforms(plats)

    //It also checks if the quote status is or has been changed to approved to disable node creation
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (data?.quote?.status === Enum_Quote_Status.Approved) {
      setIsApproved(true)
    }
  }, [data, setAllPlatforms, setIsApproved])

  //Every time the above platform state var is updated, all platforms are checked to see if any aren't accepted yet. If none aren't accepted, the approvalReady boolean value is set to true and the Approval button is enabled

  React.useEffect(() => {
    if (allPlatforms !== null && allPlatforms !== undefined) {
      if (allPlatforms.filter((plat: any) => plat.data.status !== 'ACCEPTED').length === 0) {
        setApprovalReady(true)
      } else {
        setApprovalReady(false)
      }
    }
  }, [allPlatforms, setApprovalReady])

  React.useEffect(
    () => {
      if (changedStatus !== null) {
        setElements((els: any) => {
          let changedNode: any
          const parentIndex = _.findIndex(els, { id: changedStatus.data.parent })
          if (changedStatus.type === 'featureNode') {
            const minFeature = els[
              parentIndex
            ].data.features.reduce((feat1: FeatureNodeProps, feat2: FeatureNodeProps) =>
              statusToInt(feat1.data.status) < statusToInt(feat2.data.status) ? feat1 : feat2
            )
            if (statusToInt(minFeature.data.status) > statusToInt(els[parentIndex].data.status)) {
              els[parentIndex].data.status = minFeature.data.status
              changedNode = els[parentIndex]
            } else {
              changedNode = null
            }
          } else {
            changedNode = changedStatus
          }
          if (changedNode !== null) {
            const platformIndex = _.findIndex(els, { id: changedNode.data.platform })

            let platChildren = []

            platChildren = els.filter((el: any) => {
              if (el.data?.platform) {
                if (el.data.platform === changedNode.data.platform) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            })

            const minComponent = platChildren.reduce(
              (comp1: ComponentNodeProps, comp2: ComponentNodeProps) =>
                statusToInt(comp1.data.status) < statusToInt(comp2.data.status) ? comp1 : comp2
            )

            if (
              statusToInt(minComponent.data.status) !== statusToInt(els[platformIndex].data.status)
            ) {
              els[platformIndex].data.status = minComponent.data.status
            }
          }

          // else {
          //   globalMinimum = changedStatus
          // }

          // const recur = (parent2: any) => {
          //   const parentIndex = _.findIndex(els, { id: parent2 })
          //   //Filter every node with same parent on this level
          //   const children = els.filter((el: any) => {
          //     if (el.data?.parent) {
          //       if (el.data?.parent === parent2) {
          //         return true
          //       } else {
          //         return false
          //       }
          //     } else {
          //       return false
          //     }
          //   })

          //Get minComponent and change the Parent if it has lower status
          //   const localMinimum = children.reduce(
          //     (comp1: ComponentNodeProps, comp2: ComponentNodeProps) =>
          //       statusToInt(comp1.data.status) < statusToInt(comp2.data.status) ? comp1 : comp2
          //   )

          //   if (statusToInt(localMinimum.data.status) < statusToInt(globalMinimum.data.status)) {
          //     globalMinimum.data.status = localMinimum.data.status
          //   }
          //   if (els[parentIndex].type === 'platformNode') {
          //     if (
          //       statusToInt(globalMinimum.data.status) !== statusToInt(els[parentIndex].data.status)
          //     ) {
          //       els[parentIndex].data.status = globalMinimum.data.status
          //     }
          //   }
          //   if (els[parentIndex].data.parent) {
          //     recur(els[parentIndex].data.parent)
          //   }
          // }
          // if (els[parentIndex].data.parent) {
          //   recur(els[parentIndex].data.parent)
          // }

          setChangedStatus(null)

          return els
        })
      }
    },
    // eslint-disable-next-line
    [changedStatus]
  )

  const addPlatform = useCallback(
    (name: string, description: string, type: string) => {
      const verticallySortedNodes = nodes.sort(
        (node1: any, node2: any) =>
          node1.position.y + node1.__rf.height - (node2.position.y + node2.__rf.height)
      )
      const lowestNode = verticallySortedNodes[verticallySortedNodes.length - 1]
      const getHours = (type: string) => {
        switch (type) {
          case 'dmp':
            return 160
          case 'web':
            return 160
          case 'app':
            return 160
          default:
            return 160
        }
      }
      const nodeID = uuidv4()
      const newNode = {
        id: nodeID,
        type: 'platformNode',
        data: {
          name,
          description,
          type,
          hours: getHours(type),
          status: 'ACCEPTED',
          children: [],
          comments: []
        },
        position: {
          x: 200,
          y: lowestNode ? lowestNode.position.y + lowestNode.__rf.height + 200 : 200
        }
      }
      setElements((els: any) => els.concat(newNode))

      //Updating change log
      const changelist = data?.quote?.changes || []
      const change: Change = {
        time: moment().format('DD/MM/YYYY, hh:mm A'),
        user: thisUser?.username,
        description: `Added a platform node - ${name}`,
        type: 'platformNode',
        typeID: nodeID
      }
      updateQuote({
        variables: {
          id: params.id,
          data: {
            changes: changelist.concat(change)
          }
        }
      })
    },
    // eslint-disable-next-line
    [elements, onOpenComponentModal, onOpenDetailsDrawer, nodes]
  )

  const addComponent = useCallback(
    (name: string, description: string, parent: string) => {
      //algorithm to set the position of the new Node which will be lower than the lowest positioned child if the component is added using the platform node button, or simply adjacent to the right most child if added from the component node button

      let xPosition
      let yPosition
      let edgeSource = ''
      let platform
      if (isPlatButton === true) {
        const verticallySortedChildren = nodes
          .filter((el: any) => el.data.parent === parent)
          .sort((comp1: any, comp2: any) => {
            if (comp1.position.y - comp2.position.y === 0) {
              //if equal, place the component closest to the parent plaform at the very end
              return comp2.position.x - comp1.position.x
            } else {
              return comp1.position.y - comp2.position.y
            }
          })
        let lowestChild
        if (verticallySortedChildren.length > 0) {
          lowestChild = verticallySortedChildren[verticallySortedChildren.length - 1]
          xPosition = lowestChild.position.x
          yPosition = lowestChild.position.y + lowestChild.__rf.height + 200
          edgeSource = parent
        } else {
          //else use the parent platform as 'lowestChild' and y position is equal to it
          lowestChild = elements.filter((el: any) => isNode(el) && el.id === parent)[0]
          xPosition = lowestChild.position.x + 800
          yPosition = lowestChild.position.y
          edgeSource = parent
        }
        platform = parent
      } else {
        const platChildren = nodes
          .filter((el: any) => el.data.parent === parent && el.type === 'componentNode')
          .sort((comp1: any, comp2: any) => {
            if (comp1.position.x - comp2.position.x === 0) {
              //if equal, place the higher component at the very end
              return comp2.position.y - comp1.position.y
            } else {
              return comp1.position.x - comp2.position.x
            }
          })

        let rightMostChild
        if (platChildren.length > 0) {
          rightMostChild = platChildren[platChildren.length - 1]
        } else {
          //else use the parent platform
          rightMostChild = elements.filter((el: any) => isNode(el) && el.id === parent)[0]
        }
        xPosition = rightMostChild.position.x + 700
        yPosition = rightMostChild.position.y
        edgeSource = rightMostChild.id
        platform = rightMostChild.data.platform
      }

      const nodeID = uuidv4()
      const newNode = {
        id: nodeID,
        type: 'componentNode',
        data: {
          name,
          description,
          hours: 0,
          status: 'ACCEPTED',
          platform,
          features: [],
          parent,
          comments: [],
          children: []
        },
        position: {
          x: xPosition,
          y: yPosition
        }
      }
      //edit parent platform
      setElements((els: any) => {
        els.map((el: any) => {
          if (el.id === parent) {
            el.data.children.push(newNode.id)
            el.data = {
              ...el.data,
              children: el.data.children
            }
          }

          return el
        })
        //add new edge
        const newEdge = {
          id: 'e' + edgeSource + '-' + newNode.id,
          source: parent,
          target: newNode.id,
          // type: 'smoothstep',
          // arrowHeadType: 'arrowclosed',
          // animated: true,
          style: { strokeWidth: '3', stroke: theme.colors.brand[500] }
        }
        return els.concat([newNode, newEdge])
      })

      //Updating change log
      const changelist = data?.quote?.changes || []
      const change: Change = {
        time: moment().format('DD/MM/YYYY, hh:mm A'),
        user: thisUser?.username,
        description: `Added a component node - ${name}`,
        type: 'componentNode',
        typeID: nodeID
      }
      updateQuote({
        variables: {
          id: params.id,
          data: {
            changes: changelist.concat(change)
          }
        }
      })
    },
    // eslint-disable-next-line
    [elements, onOpenDetailsDrawer, onOpenFeatureModal, onOpenComponentModal, nodes, isPlatButton]
  )

  // const traverseHoursCalc = (nodes: any) => {
  //   const parents: any = {}
  //   const processed: Array<string> = []

  //   const edges = nodes.filter(
  //     (n: any) => n.type === 'componentNode' && n.data.children.length === 0
  //   )
  //   edges.forEach((comp: any) => {
  //     parents[comp.parent] = (parents[comp.parent] || 0) + comp.hours.totalHours
  //     processed.push(comp.id)
  //   })

  //   const recurr = () => {
  //     Object.keys(parents).forEach((id: any) => {
  //       if (processed.indexOf(id) === -1) {
  //         const comp = _.find(nodes, { id })
  //         parents[comp.parent] = (parents[comp.parent] || 0) + comp.hours.totalHours
  //         processed.push(comp.id)
  //       }
  //     })
  //   }
  //   recurr()

  //   // platforms.forEach((platform: any) => {
  //   //   const recurr = (children: any) =>
  //   //     children.forEach((child: any) => {
  //   //       const id = child.id
  //   //       const index = _.findIndex(nodes, { 'id': id });
  //   //       elements[index].hours.totalHours =
  //   //       if (child.children) {
  //   //         recurr(children)
  //   //       }
  //   //     })
  //   //   recurr(platform)
  //   // })
  // }

  const addFeatureNode = useCallback(
    (
      name: string,
      description: string,
      hours: number,
      parent: string,
      template: any,
      integration: boolean
    ) => {
      const nodeID = uuidv4()
      const newNode = {
        id: nodeID,
        type: 'featureNode',
        data: {
          name,
          description,
          featureTime: hours,
          status: 'ACCEPTED',
          parent,
          template,
          comments: [],
          integration,
          custom: false
        }
      }

      setElements((els: any) => {
        const parentIndex = _.findIndex(els, { id: parent })
        els[parentIndex].data.features.push(newNode)
        els[parentIndex].data = {
          ...els[parentIndex].data,
          hours: els[parentIndex].data.hours + hours,
          features: els[parentIndex].data.features
        }

        const recur = (parent2: any) => {
          const parentIndex = _.findIndex(els, { id: parent2 })
          els[parentIndex].data.hours = els[parentIndex].data.hours + hours
          if (els[parentIndex].data.parent) {
            recur(els[parentIndex].data.parent)
          }
        }
        if (els[parentIndex].data.parent) {
          recur(els[parentIndex].data.parent)
        }
        setChangedStatus(newNode)
        return els
      })
      //Updating change log
      const changelist = data?.quote?.changes || []
      const change: Change = {
        time: moment().format('DD/MM/YYYY, hh:mm A'),
        user: thisUser?.username,
        description: `Added a feature node - ${name}`,
        type: 'featureNode',
        typeID: nodeID
      }
      updateQuote({
        variables: {
          id: params.id,
          data: {
            changes: changelist.concat(change)
          }
        }
      })
    },
    // eslint-disable-next-line
    [elements, onOpenDetailsDrawer]
  )

  //Still have to figure out how adding a component from the platform will work. Need to have dimensions of whole component to add a new one below
  //Style New Feature view slightly better

  const addCustomFeature = useCallback(
    (name: string, description: string, attachments: object, hours: string, parent: string) => {
      const nodeID = uuidv4()
      const newNode = {
        id: nodeID,
        type: 'featureNode',
        data: {
          name,
          featureTime: parseInt(hours),
          description,
          status: 'NEEDS REVIEW',
          parent,
          comments: [
            {
              id: 0,
              body: "Let's get started",
              // eslint-disable-next-line @typescript-eslint/camelcase
              created_at: moment().format('DD/MM/YYYY'),
              // eslint-disable-next-line @typescript-eslint/camelcase
              updated_at: moment().format('DD/MM/YYYY'),
              user: { username: user?.username, url: '' },
              acknowledged: false
            }
          ],
          integration: false,
          custom: true
        }
      }
      setElements((els: any) => {
        const parentIndex = _.findIndex(els, { id: parent })
        els[parentIndex].data.features.push(newNode)
        els[parentIndex].data = {
          ...els[parentIndex].data,
          hours: els[parentIndex].data.hours + parseInt(hours),
          status: 'NEEDS REVIEW',
          features: els[parentIndex].data.features
        }

        const recur = (parent2: any) => {
          const parentIndex = _.findIndex(els, { id: parent2 })
          els[parentIndex].data.hours = els[parentIndex].data.hours + parseInt(hours)
          if (els[parentIndex].data.parent) {
            recur(els[parentIndex].data.parent)
          }
        }
        if (els[parentIndex].data.parent) {
          recur(els[parentIndex].data.parent)
        }
        setChangedStatus(els[parentIndex])
        return els
      })
      //Updating change log
      const changelist = data?.quote?.changes || []
      const change: Change = {
        time: moment().format('DD/MM/YYYY, hh:mm A'),
        user: thisUser?.username,
        description: `Added a custom feature node - ${name}`,
        type: 'featureNode',
        typeID: nodeID
      }
      updateQuote({
        variables: {
          id: params.id,
          data: {
            changes: changelist.concat(change)
          }
        }
      })
    },
    // eslint-disable-next-line
    [elements, onOpenDetailsDrawer]
  )

  const onSave = useCallback(() => {
    if (rfInstance) {
      // @ts-ignore
      const flow = rfInstance.toObject()
      const nodes = flow.elements
      const hours = hoursCalc(nodes)
      updateQuote({
        variables: {
          id: params.id,
          data: {
            nodes,
            hours
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [rfInstance])

  const INITIAL_VALUES: InitialValues = {
    name: '',
    description: '',
    type: 'dmp',
    attachments: {},
    hours: 0
  }

  if (loading) {
    return <FillLoader />
  }

  return (
    <>
      <ReactFlowProvider>
        <ApprovalDrawer
          onClose={onCloseApprovalDrawer}
          isOpen={isOpenApprovalDrawer}
          data={data}
          refetch={refetch}
        />
        <HistoryDrawer onClose={onCloseHistoryDrawer} isOpen={isOpenHistoryDrawer} data={data} />
        <EditQuoteModal
          isOpen={isOpenEditQuoteModal}
          onClose={onCloseEditQuoteModal}
          quote={data?.quote}
          refetch={refetch}
        />
        <ComponentModal
          addComponent={addComponent}
          onClose={onCloseComponentModal}
          isOpen={isOpenComponentModal}
          parent={parent}
        />
        <FeatureModal
          addStdFeature={addFeatureNode}
          addCustFeature={addCustomFeature}
          setSelected={setSelectedFeature}
          openCust={onOpenCustomFeature}
          openStd={onOpenStdFeature}
          onClose={onCloseFeatureModal}
          isOpen={isOpenFeatureModal}
        />
        <MakeTemplateModal
          isOpen={isOpenMakeTemplateModal}
          onClose={onCloseMakeTemplateModal}
          refetch={refetch}
          data={data}
        />
        <StandardFeatureView
          addStdFeature={addFeatureNode}
          addCustFeature={addCustomFeature}
          // selected={selectedFeature}
          onClose={onCloseStdFeature}
          isOpen={isOpenStdFeature}
          openFeat={onOpenFeatureModal}
          // parent={parent}
        />
        <ModalWrap
          title="New Platform Node"
          isOpen={isOpenPlatformModal}
          onClose={onClosePlatformModal}
        >
          <Flex direction="column">
            <Formik
              validationSchema={AddNodeFormValidation}
              initialValues={INITIAL_VALUES}
              onSubmit={({ name, description, type }, { setStatus }) => {
                try {
                  addPlatform(name, description, type)
                  toast({ description: `New Platform added.`, ...SUCCESS_TOAST })
                  onClosePlatformModal()
                } catch (error) {
                  setStatus(formatError(error))
                  toast({ description: `Error adding Platform.`, ...ERROR_TOAST })
                }
              }}
            >
              {({ status, handleSubmit }: FormikProps<InitialValues>) => {
                return (
                  <Form style={{ width: '100%' }}>
                    <Flex flexDir="column" p={4}>
                      <ConnectedFormGroup
                        name="name"
                        label="Name"
                        placeholder={`What should we call this Platform?`}
                      />
                      <ConnectedTextarea
                        name="description"
                        label="Description"
                        placeholder={`Tell us a bit about what this Platform does?`}
                      />
                      <ConnectedSelect
                        name="type"
                        label="Type"
                        options={[
                          { label: 'Data Management Portal', value: 'dmp' },
                          { label: 'Progressive Web Application', value: 'web' },
                          { label: 'Mobile Application', value: 'app' }
                        ]}
                      />
                      {status && (
                        <Text textAlign="right" color="red.500">
                          {status}
                        </Text>
                      )}
                      <Divider marginBottom={3} />
                      {isApproved && (
                        <Text fontSize="sm" color="gray.600" mb={4}>
                          <Icon name="info" mr={1} size="15px" />
                          This quote has been approved and cannot have further nodes added to it.
                        </Text>
                      )}
                      <Flex justifyContent="flex-end">
                        <Button
                          onClick={() => {
                            onClosePlatformModal()
                          }}
                          size="sm"
                          marginX={2}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          variantColor="brand"
                          size="sm"
                          marginX={2}
                          paddingX={6}
                          isDisabled={isApproved}
                        >
                          Add Platform
                        </Button>
                      </Flex>
                    </Flex>
                  </Form>
                )
              }}
            </Formik>
          </Flex>
        </ModalWrap>
        <DetailsDrawer
          removeElements={onElementsRemove}
          setElements={setElements}
          node={selectedNode}
          elements={elements}
          onClose={onCloseDetailsDrawer}
          isOpen={isOpenDetailsDrawer}
          data={data}
          refetch={refetch}
        />
        <BuilderHeader
          clientName={data?.quote?.project?.client?.companyName}
          projectID={data?.quote?.project?.id}
          quoteID={data?.quote?.id}
          projectName={data?.quote?.project?.name}
          quoteName={data?.quote?.name}
          timeline={data?.quote?.hours}
          partner={data?.quote?.project?.partner}
        />
        <BuilderCanvas
          onDoubleClick={(e) => {
            e.stopPropagation()
            onOpenPlatformModal()
          }}
        >
          <ReactFlow
            onLoad={setRfInstance}
            elements={elements}
            nodeTypes={nodeTypes}
            minZoom={0.1}
            defaultZoom={0.001}
            onEdgeUpdate={onEdgeUpdate}
            onConnect={onConnect}
            onNodeDragStop={onSave}
            zoomOnDoubleClick={false}
            onNodeDoubleClick={(e) => {
              e.stopPropagation()
            }}
            snapToGrid
            snapGrid={[50, 50]}
          >
            <Background variant={BackgroundVariant.Dots} gap={50} size={2} />
            <Controls
              onDoubleClickCapture={(e) => {
                e.stopPropagation()
              }}
              onDoubleClick={(e) => {
                e.stopPropagation()
              }}
            />
            <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} />
            <NodesDimensions />
          </ReactFlow>
        </BuilderCanvas>
      </ReactFlowProvider>
    </>
  )
}

export default QuoteBuilder
