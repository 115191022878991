import { IToast } from '@chakra-ui/core'
import { UploadFile } from '../generated/graphql'

export const APP_NAME = 'SovTech Portal'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const industryOptions = [
  { label: 'Accounting', value: 'Accounting' },
  { label: 'Airlines/Aviation', value: 'Airlines/Aviation' },
  { label: 'Alternative Dispute Resolution', value: 'Alternative Dispute Resolution' },
  { label: 'Alternative Medicine', value: 'Alternative Medicine' },
  { label: 'Animation', value: 'Animation' },
  { label: 'Apparel/Fashion', value: 'Apparel/Fashion' },
  { label: 'Architecture/Planning', value: 'Architecture/Planning' },
  { label: 'Arts/Crafts', value: 'Arts/Crafts' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Aviation/Aerospace', value: 'Aviation/Aerospace' },
  { label: 'Banking/Mortgage', value: 'Banking/Mortgage' },
  { label: 'Biotechnology/Greentech', value: 'Biotechnology/Greentech' },
  { label: 'Broadcast Media', value: 'Broadcast Media' },
  { label: 'Building Materials', value: 'Building Materials' },
  { label: 'Business Supplies/Equipment', value: 'Business Supplies/Equipment' },
  {
    label: 'Capital Markets/Hedge Fund/Private Equity',
    value: 'Capital Markets/Hedge Fund/Private Equity'
  },
  { label: 'Chemicals', value: 'Chemicals' },
  { label: 'Civic/Social Organization', value: 'Civic/Social Organization' },
  { label: 'Civil Engineering', value: 'Civil Engineering' },
  { label: 'Commercial Real Estate', value: 'Commercial Real Estate' },
  { label: 'Computer Games', value: 'Computer Games' },
  { label: 'Computer Hardware', value: 'Computer Hardware' },
  { label: 'Computer Networking', value: 'Computer Networking' },
  { label: 'Computer Software/Engineering', value: 'Computer  Software/Engineering' },
  { label: 'Computer/Network Security', value: 'Computer/Network Security' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Consumer Electronics', value: 'Consumer Electronics' },
  { label: 'Consumer Goods', value: 'Consumer Goods' },
  { label: 'Consumer Services', value: 'Consumer Services' },
  { label: 'Cosmetics', value: 'Cosmetics' },
  { label: 'Dairy', value: 'Dairy' },
  { label: 'Defense/Space', value: 'Defense/Space' },
  { label: 'Design', value: 'Design' },
  { label: 'E-Learning', value: 'E-Learning' },
  { label: 'Education Management', value: 'Education Management' },
  { label: 'Electrical/Electronic Manufacturing', value: 'Electrical/Electronic Manufacturing' },
  { label: 'Entertainment/Movie Production', value: 'Entertainment/Movie Production' },
  { label: 'Environmental Services', value: 'Environmental Services' },
  { label: 'Events Services', value: 'Events Services' },
  { label: 'Executive Office', value: 'Executive Office' },
  { label: 'Facilities Services', value: 'Facilities Services' },
  { label: 'Farming', value: 'Farming' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Fine Art', value: 'Fine Art' },
  { label: 'Fishery', value: 'Fishery' },
  { label: 'Food Production', value: 'Food Production' },
  { label: 'Food/Beverages', value: 'Food/Beverages' },
  { label: 'Fundraising', value: 'Fundraising' },
  { label: 'Furniture', value: 'Furniture' },
  { label: 'Gambling/Casinos', value: 'Gambling/Casinos' },
  { label: 'Glass/Ceramics/Concrete', value: 'Glass/Ceramics/Concrete' },
  { label: 'Government Administration', value: 'Government Administration' },
  { label: 'Government Relations', value: 'Government Relations' },
  { label: 'Graphic Design/Web Design', value: 'Graphic Design/Web Design' },
  { label: 'Health/Fitness', value: 'Health/Fitness' },
  { label: 'Higher Education/Acadamia', value: 'Higher Education/Acadamia' },
  { label: 'Hospital/Health Care', value: 'Hospital/Health Care' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Human Resources/HR', value: 'Human Resources/HR' },
  { label: 'Import/Export', value: 'Import/Export' },
  { label: 'Individual/Family Services', value: 'Individual/Family Services' },
  { label: 'Industrial Automation', value: 'Industrial Automation' },
  { label: 'Information Services', value: 'Information Services' },
  { label: 'Information Technology/IT', value: 'Information Technology/IT' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'International Affairs', value: 'International Affairs' },
  { label: 'International Trade/Development', value: 'International/Development' },
  { label: 'Internet', value: 'Internet' },
  { label: 'Investment Banking/Venture', value: 'Investment Banking/Venture' },
  {
    label: 'Investment Management/Hedge Fund/Private Equity',
    value: 'Investment Management/Hedge Fund/Private Equity'
  },
  { label: 'Judiciary', value: 'Judiciary' },
  { label: 'Law Enforcement', value: 'Law Enforcement' },
  { label: 'Law Practice/Law Firms', value: 'Law Practice/Law Firms' },
  { label: 'Legal Services', value: 'Legal Services' },
  { label: 'Legislative Office', value: 'Legislative Office' },
  { label: 'Leisure/Travel', value: 'Leisure/Travel' },
  { label: 'Library', value: 'Library' },
  { label: 'Logistics/Procurement', value: 'Logistics/Procurement' },
  { label: 'Luxury Goods/Jewelry', value: 'Luxury Goods/Jewelry' },
  { label: 'Machinery', value: 'Machinery' },
  { label: 'Management Consulting', value: 'Management Consulting' },
  { label: 'Maritime', value: 'Maritime' },
  { label: 'Market Research', value: 'Market Research' },
  { label: 'Marketing/Advertising/Sales', value: 'Marketing/Advertising/Sales' },
  { label: 'Mechanical or Industrial Engineering', value: 'Mechanical or Industrial Engineering' },
  { label: 'Media Production', value: 'Media Production' },
  { label: 'Medical Equipment', value: 'Medical Equipment' },
  { label: 'Medical Practice', value: 'Medical Practice' },
  { label: 'Mental Health Care', value: 'Mental Health Care' },
  { label: 'Military Industry', value: 'Military Industry' },
  { label: 'Mining/Metals', value: 'Mining/Metals' },
  { label: 'Motion Pictures/Film', value: 'Motion Pictures/Film' },
  { label: 'Museums/Institutions', value: 'Museums/Institutions' },
  { label: 'Music', value: 'Music' },
  { label: 'Nanotechnology', value: 'Nanotechnology' },
  { label: 'Newspapers/Journalism', value: 'Newspapers/Journalism' },
  { label: 'Non-Profit/Volunteering', value: 'Non-Profit/Volunteering' },
  { label: 'Oil/Energy/Solar/Greentech', value: 'Oil/Energy/Solar/Greentech' },
  { label: 'Online Publishing', value: 'Online Publishing' },
  { label: 'Other Industry', value: 'Other Industry' },
  { label: 'Outsourcing/Offshoring', value: 'Outsourcing/Offshoring' },
  { label: 'Package/Freight Delivery', value: 'Package/Freight Delivery' },
  { label: 'Packaging/Containers', value: 'Packaging/Containers' },
  { label: 'Paper/Forest Products', value: 'Paper/Forest Products' },
  { label: 'Performing Arts', value: 'Performing Arts' },
  { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
  { label: 'Philanthropy', value: 'Philanthropy' },
  { label: 'Photography', value: 'Photography' },
  { label: 'Plastics', value: 'Plastics' },
  { label: 'Political Organization', value: 'Political Organization' },
  { label: 'Primary/Secondary Education', value: 'Primary/Secondary Education' },
  { label: 'Printing', value: 'Printing' },
  { label: 'Professional Training', value: 'Professional Training' },
  { label: 'Program Development', value: 'Program Development' },
  { label: 'Public Relations/PR', value: 'Public Relations/PR' },
  { label: 'Public Safety', value: 'Public Safety' },
  { label: 'Publishing Industry', value: 'Publishing Industry' },
  { label: 'Railroad Manufacture', value: 'Railroad Manufacture' },
  { label: 'Ranching', value: 'Ranching' },
  { label: 'Real Estate/Mortgage', value: 'Real Estate/Mortgage' },
  { label: 'Recreational Facilities/Services', value: 'Recreational Facilities/Services' },
  { label: 'Religious Institutions', value: 'Religious Institutions' },
  { label: 'Renewables/Environment', value: 'Renewables/Environment' },
  { label: 'Research Industry', value: 'Research Industry' },
  { label: 'Restaurants', value: 'Restaurants' },
  { label: 'Retail Industry', value: 'Retail Industry' },
  { label: 'Security/Investigations', value: 'Security/Investigations' },
  { label: 'Semiconductors', value: 'Semiconductors' },
  { label: 'Shipbuilding', value: 'Shipbuilding' },
  { label: 'Sporting Goods', value: 'Sporting Goods' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Staffing/Recruiting', value: 'Staffing/Recruiting' },
  { label: 'Supermarkets', value: 'Supermarkets' },
  { label: 'Telecommunications', value: 'Telecommunications' },
  { label: 'Textiles', value: 'Textiles' },
  { label: 'Think Tanks', value: 'Think Tanks' },
  { label: 'Tobacco', value: 'Tobacco' },
  { label: 'Translation/Localization', value: 'Translation/Localization' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Venture Capital/VC', value: 'Venture Capital/VC' },
  { label: 'Veterinary', value: 'Veterinary' },
  { label: 'Warehousing', value: 'Warehousing' },
  { label: 'Wholesale', value: 'Wholesale' },
  { label: 'Wine/Spirits', value: 'Wine' },
  { label: 'Wireless', value: 'Wireless' },
  { label: 'Writing/Editing', value: 'Writing/Editing' }
]

export const countryOptions = [
  { label: 'Afghanistan (AF)', value: 'Afghanistan (AF)' },
  { label: 'Åland Islands (AX)', value: 'Åland Islands (AX)' },
  { label: 'Albania (AL)', value: 'Albania (AL)' },
  { label: 'Algeria (DZ)', value: 'Algeria (DZ)' },
  { label: 'American Samoa (AS)', value: 'American Samoa (AS)' },
  { label: 'Andorra (AD)', value: 'Andorra (AD)' },
  { label: 'Angola (AO)', value: 'Angola (AO)' },
  { label: 'Anguilla (AI)', value: 'Anguilla (AI)' },
  { label: 'Antarctica (AQ)', value: 'Antarctica (AQ)' },
  { label: 'Antigua & Barbuda (AG)', value: 'Antigua & Barbuda (AG)' },
  { label: 'Argentina (AR)', value: 'Argentina (AR)' },
  { label: 'Armenia (AM)', value: 'Armenia (AM)' },
  { label: 'Aruba (AW)', value: 'Aruba (AW)' },
  { label: 'Australia (AU)', value: 'Australia (AU)' },
  { label: 'Austria (AT)', value: 'Austria (AT)' },
  { label: 'Azerbaijan (AZ)', value: 'Azerbaijan (AZ)' },
  { label: 'Bahamas (BS)', value: 'Bahamas (BS)' },
  { label: 'Bahrain (BH)', value: 'Bahrain (BH)' },
  { label: 'Bangladesh (BD)', value: 'Bangladesh (BD)' },
  { label: 'Barbados (BB)', value: 'Barbados (BB)' },
  { label: 'Belarus (BY)', value: 'Belarus (BY)' },
  { label: 'Belgium (BE)', value: 'Belgium (BE)' },
  { label: 'Belize (BZ)', value: 'Belize (BZ)' },
  { label: 'Benin (BJ)', value: 'Benin (BJ)' },
  { label: 'Bermuda (BM)', value: 'Bermuda (BM)' },
  { label: 'Bhutan (BT)', value: 'Bhutan (BT)' },
  { label: 'Bolivia (BO)', value: 'Bolivia (BO)' },
  { label: 'Bosnia & Herzegovina (BA)', value: 'Bosnia & Herzegovina (BA)' },
  { label: 'Botswana (BW)', value: 'Botswana (BW)' },
  { label: 'Bouvet Island (BV)', value: 'Bouvet Island (BV)' },
  { label: 'Brazil (BR)', value: 'Brazil (BR)' },
  { label: 'British Indian Ocean Territory (IO)', value: 'British Indian Ocean Territory (IO)' },
  { label: 'British Virgin Islands (VG)', value: 'British Virgin Islands (VG)' },
  { label: 'Brunei (BN)', value: 'Brunei (BN)' },
  { label: 'Bulgaria (BG)', value: 'Bulgaria (BG)' },
  { label: 'Burkina Faso (BF)', value: 'Burkina Faso (BF)' },
  { label: 'Burundi (BI)', value: 'Burundi (BI)' },
  { label: 'Cambodia (KH)', value: 'Cambodia (KH)' },
  { label: 'Cameroon (CM)', value: 'Cameroon (CM)' },
  { label: 'Canada (CA)', value: 'Canada (CA)' },
  { label: 'Cape Verde (CV)', value: 'Cape Verde (CV)' },
  { label: 'Caribbean Netherlands (BQ)', value: 'Caribbean Netherlands (BQ)' },
  { label: 'Cayman Islands (KY)', value: 'Cayman Islands (KY)' },
  { label: 'Central African Republic (CF)', value: 'Central African Republic (CF)' },
  { label: 'Chad (TD)', value: 'Chad (TD)' },
  { label: 'Chile (CL)', value: 'Chile (CL)' },
  { label: 'China (CN)', value: 'China (CN)' },
  { label: 'Christmas Island (CX)', value: 'Christmas Island (CX)' },
  { label: 'Cocos (Keeling) Islands (CC)', value: 'Cocos (Keeling)' },
  { label: 'Colombia (CO)', value: 'Colombia (CO)' },
  { label: 'Comoros (KM)', value: 'Comoros (KM)' },
  { label: 'Congo - Brazzaville (CG)', value: 'Congo - Brazzaville (CG)' },
  { label: 'Congo - Kinshasa (CD)', value: 'Congo - Kinshasa (CD)' },
  { label: 'Cook Islands (CK)', value: 'Cook Islands (CK)' },
  { label: 'Costa Rica (CR)', value: 'Costa Rica (CR)' },
  { label: 'Côte d’Ivoire (CI)', value: 'Côte d’Ivoire (CI)' },
  { label: 'Croatia (HR)', value: 'Croatia (HR)' },
  { label: 'Cuba (CU)', value: 'Cuba (CU)' },
  { label: 'Curaçao (CW)', value: 'Curaçao (CW)' },
  { label: 'Cyprus (CY)', value: 'Cyprus (CY)' },
  { label: 'Czechia (CZ)', value: 'Czechia (CZ)' },
  { label: 'Denmark (DK)', value: 'Denmark (DK)' },
  { label: 'Djibouti (DJ)', value: 'Djibouti (DJ)' },
  { label: 'Dominica (DM)', value: 'Dominica (DM)' },
  { label: 'Dominican Republic (DO)', value: 'Dominican Republic (DO)' },
  { label: 'Ecuador (EC)', value: 'Ecuador (EC)' },
  { label: 'Egypt (EG)', value: 'Egypt (EG)' },
  { label: 'El Salvador (SV)', value: 'El Salvador (SV)' },
  { label: 'Equatorial Guinea (GQ)', value: 'Equatorial Guinea (GQ)' },
  { label: 'Eritrea (ER)', value: 'Eritrea (ER)' },
  { label: 'Estonia (EE)', value: 'Estonia (EE)' },
  { label: 'Eswatini (SZ)', value: 'Eswatini (SZ)' },
  { label: 'Ethiopia (ET)', value: 'Ethiopia (ET)' },
  { label: 'Falkland Islands (FK)', value: 'Falkland Islands (FK)' },
  { label: 'Faroe Islands (FO)', value: 'Faroe Islands (FO)' },
  { label: 'Fiji (FJ)', value: 'Fiji (FJ)' },
  { label: 'Finland (FI)', value: 'Finland (FI)' },
  { label: 'France (FR)', value: 'France (FR)' },
  { label: 'French Guiana (GF)', value: 'French Guiana (GF)' },
  { label: 'French Polynesia (PF)', value: 'French Polynesia (PF)' },
  { label: 'French Southern Territories (TF)', value: 'French Southern (TF)' },
  { label: 'Gabon (GA)', value: 'Gabon (GA)' },
  { label: 'Gambia (GM)', value: 'Gambia (GM)' },
  { label: 'Georgia (GE)', value: 'Georgia (GE)' },
  { label: 'Germany (DE)', value: 'Germany (DE)' },
  { label: 'Ghana (GH)', value: 'Ghana (GH)' },
  { label: 'Gibraltar (GI)', value: 'Gibraltar (GI)' },
  { label: 'Greece (GR)', value: 'Greece (GR)' },
  { label: 'Greenland (GL)', value: 'Greenland (GL)' },
  { label: 'Grenada (GD)', value: 'Grenada (GD)' },
  { label: 'Guadeloupe (GP)', value: 'Guadeloupe (GP)' },
  { label: 'Guam (GU)', value: 'Guam (GU)' },
  { label: 'Guatemala (GT)', value: 'Guatemala (GT)' },
  { label: 'Guernsey (GG)', value: 'Guernsey (GG)' },
  { label: 'Guinea (GN)', value: 'Guinea (GN)' },
  { label: 'Guinea-Bissau (GW)', value: 'Guinea-Bissau (GW)' },
  { label: 'Guyana (GY)', value: 'Guyana (GY)' },
  { label: 'Haiti (HT)', value: 'Haiti (HT)' },
  { label: 'Heard & McDonald Islands (HM)', value: 'Heard & McDonald Islands (HM)' },
  { label: 'Honduras (HN)', value: 'Honduras (HN)' },
  { label: 'Hong Kong SAR China (HK)', value: 'Hong Kong SAR China (HK)' },
  { label: 'Hungary (HU)', value: 'Hungary (HU)' },
  { label: 'Iceland (IS)', value: 'Iceland (IS)' },
  { label: 'India (IN)', value: 'India (IN)' },
  { label: 'Indonesia (ID)', value: 'Indonesia (ID)' },
  { label: 'Iran (IR)', value: 'Iran (IR)' },
  { label: 'Iraq (IQ)', value: 'Iraq (IQ)' },
  { label: 'Ireland (IE)', value: 'Ireland (IE)' },
  { label: 'Isle of Man (IM)', value: 'Isle of ' },
  { label: 'Israel (IL)', value: 'Israel (IL)' },
  { label: 'Italy (IT)', value: 'Italy (IT)' },
  { label: 'Jamaica (JM)', value: 'Jamaica (JM)' },
  { label: 'Japan (JP)', value: 'Japan (JP)' },
  { label: 'Jersey (JE)', value: 'Jersey (JE)' },
  { label: 'Jordan (JO)', value: 'Jordan (JO)' },
  { label: 'Kazakhstan (KZ)', value: 'Kazakhstan (KZ)' },
  { label: 'Kenya (KE)', value: 'Kenya (KE)' },
  { label: 'Kiribati (KI)', value: 'Kiribati (KI)' },
  { label: 'Kuwait (KW)', value: 'Kuwait (KW)' },
  { label: 'Kyrgyzstan (KG)', value: 'Kyrgyzstan (KG)' },
  { label: 'Laos (LA)', value: 'Laos (LA)' },
  { label: 'Latvia (LV)', value: 'Latvia (LV)' },
  { label: 'Lebanon (LB)', value: 'Lebanon (LB)' },
  { label: 'Lesotho (LS)', value: 'Lesotho (LS)' },
  { label: 'Liberia (LR)', value: 'Liberia (LR)' },
  { label: 'Libya (LY)', value: 'Libya (LY)' },
  { label: 'Liechtenstein (LI)', value: 'Liechtenstein (LI)' },
  { label: 'Lithuania (LT)', value: 'Lithuania (LT)' },
  { label: 'Luxembourg (LU)', value: 'Luxembourg (LU)' },
  { label: 'Macao SAR China (MO)', value: 'Macao SAR China (MO)' },
  { label: 'Madagascar (MG)', value: 'Madagascar (MG)' },
  { label: 'Malawi (MW)', value: 'Malawi (MW)' },
  { label: 'Malaysia (MY)', value: 'Malaysia (MY)' },
  { label: 'Maldives (MV)', value: 'Maldives (MV)' },
  { label: 'Mali (ML)', value: 'Mali (ML)' },
  { label: 'Malta (MT)', value: 'Malta (MT)' },
  { label: 'Marshall Islands (MH)', value: 'Marshall Islands  (MH)' },
  { label: 'Martinique (MQ)', value: 'Martinique (MQ)' },
  { label: 'Mauritania (MR)', value: 'Mauritania (MR)' },
  { label: 'Mauritius (MU)', value: 'Mauritius (MU)' },
  { label: 'Mayotte (YT)', value: 'Mayotte (YT)' },
  { label: 'Mexico (MX)', value: 'Mexico (MX)' },
  { label: 'Micronesia (FM)', value: 'Micronesia (FM)' },
  { label: 'Moldova (MD)', value: 'Moldova (MD)' },
  { label: 'Monaco (MC)', value: 'Monaco (MC)' },
  { label: 'Mongolia (MN)', value: 'Mongolia (MN)' },
  { label: 'Montenegro (ME)', value: 'Montenegro (ME)' },
  { label: 'Montserrat (MS)', value: 'Montserrat (MS)' },
  { label: 'Morocco (MA)', value: 'Morocco (MA)' },
  { label: 'Mozambique (MZ)', value: 'Mozambique (MZ)' },
  { label: 'Myanmar (Burma) (MM)', value: 'Myanmar (Burma)' },
  { label: 'Namibia (NA)', value: 'Namibia (NA)' },
  { label: 'Nauru (NR)', value: 'Nauru (NR)' },
  { label: 'Nepal (NP)', value: 'Nepal (NP)' },
  { label: 'Netherlands (NL)', value: 'Netherlands (NL)' },
  { label: 'New Caledonia (NC)', value: 'New Caledonia (NC)' },
  { label: 'New Zealand (NZ)', value: 'New Zealand (NZ)' },
  { label: 'Nicaragua (NI)', value: 'Nicaragua (NI)' },
  { label: 'Niger (NE)', value: 'Niger (NE)' },
  { label: 'Nigeria (NG)', value: 'Nigeria (NG)' },
  { label: 'Niue (NU)', value: 'Niue (NU)' },
  { label: 'Norfolk Island (NF)', value: 'Norfolk Island (NF)' },
  { label: 'North Korea (KP)', value: 'North Korea (KP)' },
  { label: 'North Macedonia (MK)', value: 'North Macedonia (MK)' },
  { label: 'Northern Mariana Islands (MP)', value: 'Northern Mariana Islands (MP)' },
  { label: 'Norway (NO)', value: 'Norway (NO)' },
  { label: 'Oman (OM)', value: 'Oman (OM)' },
  { label: 'Pakistan (PK)', value: 'Pakistan (PK)' },
  { label: 'Palau (PW)', value: 'Palau (PW)' },
  { label: 'Palestinian Territories (PS)', value: 'Palestinian Territories (PS)' },
  { label: 'Panama (PA)', value: 'Panama (PA)' },
  { label: 'Papua New Guinea (PG)', value: 'Papua New Guinea (PG)' },
  { label: 'Paraguay (PY)', value: 'Paraguay (PY)' },
  { label: 'Peru (PE)', value: 'Peru (PE)' },
  { label: 'Philippines (PH)', value: 'Philippines (PH)' },
  { label: 'Pitcairn Islands (PN)', value: 'Pitcairn Islands (PN)' },
  { label: 'Poland (PL)', value: 'Poland (PL)' },
  { label: 'Portugal (PT)', value: 'Portugal (PT)' },
  { label: 'Puerto Rico (PR)', value: 'Puerto Rico (PR)' },
  { label: 'Qatar (QA)', value: 'Qatar (QA)' },
  { label: 'Réunion (RE)', value: 'Réunion (RE)' },
  { label: 'Romania (RO)', value: 'Romania (RO)' },
  { label: 'Russia (RU)', value: 'Russia (RU)' },
  { label: 'Rwanda (RW)', value: 'Rwanda (RW)' },
  { label: 'Samoa (WS)', value: 'Samoa (WS)' },
  { label: 'San Marino (SM)', value: 'San Marino (SM)' },
  { label: 'São Tomé & Príncipe (ST)', value: 'São Tomé & Príncipe (ST)' },
  { label: 'Saudi Arabia (SA)', value: 'Saudi Arabia (SA)' },
  { label: 'Senegal (SN)', value: 'Senegal (SN)' },
  { label: 'Serbia (RS)', value: 'Serbia (RS)' },
  { label: 'Seychelles (SC)', value: 'Seychelles (SC)' },
  { label: 'Sierra Leone (SL)', value: 'Sierra Leone (SL)' },
  { label: 'Singapore (SG)', value: 'Singapore (SG)' },
  { label: 'Sint Maarten (SX)', value: 'Sint Maarten (SX)' },
  { label: 'Slovakia (SK)', value: 'Slovakia (SK)' },
  { label: 'Slovenia (SI)', value: 'Slovenia (SI)' },
  { label: 'Solomon Islands (SB)', value: 'Solomon Islands (SB)' },
  { label: 'Somalia (SO)', value: 'Somalia (SO)' },
  { label: 'South Africa (ZA)', value: 'South Africa ' },
  {
    label: 'South Georgia & South Sandwich Islands (GS)',
    value: 'South Georgia & South Sandwich Islands (GS)'
  },
  { label: 'South Korea (KR)', value: 'South Korea (KR)' },
  { label: 'South Sudan (SS)', value: 'South Sudan (SS)' },
  { label: 'Spain (ES)', value: 'Spain (ES)' },
  { label: 'Sri Lanka (LK)', value: 'Sri Lanka (LK)' },
  { label: 'St. Barthélemy (BL)', value: 'St. Barthélemy (BL)' },
  { label: 'St. Helena (SH)', value: 'St.  Helena (SH)' },
  { label: 'St. Kitts & Nevis (KN)', value: 'St. Kitts & Nevis (KN)' },
  { label: 'St. Lucia (LC)', value: 'St. Lucia (LC)' },
  { label: 'St. Martin (MF)', value: 'St. Martin (MF)' },
  { label: 'St. Pierre & Miquelon (PM)', value: 'St. Pierre & Miquelon (PM)' },
  { label: 'St. Vincent & Grenadines (VC)', value: 'St. Vincent & Grenadines (VC)' },
  { label: 'Sudan (SD)', value: 'Sudan (SD)' },
  { label: 'Suriname (SR)', value: 'Suriname (SR)' },
  { label: 'Svalbard & Jan Mayen (SJ)', value: 'Svalbard & Jan Mayen (SJ)' },
  { label: 'Sweden (SE)', value: 'Sweden (SE)' },
  { label: 'Switzerland (CH)', value: 'Switzerland (CH)' },
  { label: 'Syria (SY)', value: 'Syria (SY)' },
  { label: 'Taiwan (TW)', value: 'Taiwan (TW)' },
  { label: 'Tajikistan (TJ)', value: 'Tajikistan (TJ)' },
  { label: 'Tanzania (TZ)', value: 'Tanzania (TZ)' },
  { label: 'Thailand (TH)', value: 'Thailand (TH)' },
  { label: 'Timor-Leste (TL)', value: 'Timor-Leste (TL)' },
  { label: 'Togo (TG)', value: 'Togo (TG)' },
  { label: 'Tokelau (TK)', value: 'Tokelau (TK)' },
  { label: 'Tonga (TO)', value: 'Tonga (TO)' },
  { label: 'Trinidad & Tobago (TT)', value: 'Trinidad & Tobago (TT)' },
  { label: 'Tunisia (TN)', value: 'Tunisia (TN)' },
  { label: 'Turkey (TR)', value: 'Turkey (TR)' },
  { label: 'Turkmenistan (TM)', value: 'Turkmenistan (TM)' },
  { label: 'Turks & Caicos Islands (TC)', value: 'Turks & Caicos Islands (TC)' },
  { label: 'Tuvalu (TV)', value: 'Tuvalu (TV)' },
  { label: 'U.S. Outlying Islands (UM)', value: 'U.S. Outlying Islands (UM)' },
  { label: 'U.S. Virgin Islands (VI)', value: 'U.S. Virgin Islands (VI)' },
  { label: 'Uganda (UG)', value: 'Uganda (UG)' },
  { label: 'Ukraine (UA)', value: 'Ukraine (UA)' },
  { label: 'United Arab Emirates (AE)', value: 'United Arab Emirates (AE)' },
  { label: 'United Kingdom (GB)', value: 'United Kingdom (GB)' },
  { label: 'United States (US)', value: 'United States (US)' },
  { label: 'Uruguay (UY)', value: 'Uruguay (UY)' },
  { label: 'Uzbekistan (UZ)', value: 'Uzbekistan (UZ)' },
  { label: 'Vanuatu (VU)', value: 'Vanuatu (VU)' },
  { label: 'Vatican City (VA)', value: 'Vatican City (VA)' },
  { label: 'Venezuela (VE)', value: 'Venezuela (VE)' },
  { label: 'Vietnam (VN)', value: 'Vietnam (VN)' },
  { label: 'Wallis & Futuna (WF)', value: 'Wallis & Futuna (WF)' },
  { label: 'Western Sahara (EH)', value: 'Western Sahara (EH)' },
  { label: 'Yemen (YE)', value: 'Yemen (YE)' },
  { label: 'Zambia (ZM)', value: 'Zambia (ZM)' },
  { label: 'Zimbabwe (ZW)', value: 'Zimbabwe (ZW)' }
]
