import React, { memo, useState } from 'react'
import { Box, Text, Button, Stack, Avatar, Icon, Badge, Divider, Flex, Link } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { Node } from '../index'
import { Handle, Position } from 'react-flow-renderer'
import { images, theme } from '../../../theme'
import { isValidConnection } from '../index'
import { Comment } from './FeatureNode'
import { useQuoteContext } from '../../../context/QuoteProvider'
import { useAuthContext } from '../../../context/AuthProvider'

type PlatformData = {
  name: string
  description: string
  type: string
  hours: number
  comments: Comment[]
  status: string
  children: any[]
}

//status to enum pending DB restructure

type PlatformNodeProps = Node & {
  data: PlatformData
}

interface VertexProps {
  top?: string
  bottom?: string
}
interface NodeComponentProps {
  background?: string
  color?: string
}

interface IndicatorProps {
  color?: string
}

export const Indicator = styled.div<IndicatorProps>`
  height: inherit;
  width: 8px;
  background-color: ${(props: IndicatorProps) => props.color};
  border-radius: 99px;
  margin-right: 15px;
`

export const NodeComponent = styled.div<NodeComponentProps>`
  background: ${(props: NodeComponentProps) => props.background};
  color: ${(props: NodeComponentProps) => props.color};
  border-radius: 4px;
  width: 300px;
`
//Visible dot symbol whilst still maintaining this big connectable handle
export const Vertex = styled(Handle)<VertexProps>`
  top: ${(props: VertexProps) => props.top};
  bottom: ${(props: VertexProps) => props.bottom};
  width: 11px;
  height: 11px;
  background: #b1b1b7;
  border: 38px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box;
`

export const getVariantColor = (type: string) => {
  switch (type) {
    case 'NEEDS REVIEW':
      return theme.colors.red[200]
    case 'IN REVIEW':
      return theme.colors.yellow[300]
    case 'ACCEPTED':
      return theme.colors.green[200]
    default:
      return 'gray'
  }
}

export const getFontColor = (type: string) => {
  switch (type) {
    case 'NEEDS REVIEW':
      return 'red.500'
    case 'IN REVIEW':
      return 'black'
    case 'ACCEPTED':
      return 'green.600'
    default:
      return 'black'
  }
}

export const getPlatIcon = (type: string) => {
  switch (type) {
    case 'dmp':
      return images.dmp
    case 'web':
      return images.web
    case 'app':
      return images.app
    default:
      return ''
  }
}

const PlatformNode: React.FC<PlatformNodeProps> = (props) => {
  const [readMore, setReadMore] = useState<boolean>(false)
  const {
    onOpenComponentModal,
    setSelectedNode,
    onOpenDetailsDrawer,
    setParent,
    setIsPlatButton,
    isApproved,
    clientView
  } = useQuoteContext()
  const { user } = useAuthContext()
  const linkName = readMore ? 'Read Less' : 'Read More'
  let summary = null
  let details
  if (props.data.description?.length > 264) {
    summary = <Text>{props.data.description.slice(0, 314)}</Text>
    details = <Text>{props.data.description.slice(315, -1)}</Text>
  }
  return (
    <Flex mr={-1}>
      <Flex>
        <Box
          width="lg"
          mx="auto"
          bg="white"
          rounded="6px 0px 0px 6px"
          padding="0 0 0 4"
          shadow="1px 1px 4px"
          pr="6"
        >
          <Stack direction="row" spacing="3" align="stretch" height="auto" minH="185px">
            <Indicator color={theme.colors.purple[300]} />
            <Stack spacing="4" mr={2} mt={4}>
              <Avatar
                size="xl"
                src={getPlatIcon(props.data?.type)}
                name="Platform"
                bg="brand.700"
                color="white"
              />
              <Button
                backgroundColor="brand.100"
                alignItems="center"
                width="min-content"
                mx="auto"
                _hover={{ bg: 'brand.200' }}
                onClick={() => {
                  setSelectedNode({
                    id: props.id,
                    type: props.type,
                    data: props.data,
                    position: props.position
                  })
                  onOpenDetailsDrawer()
                }}
              >
                <Icon name="edit" mr={1} size="13px" /> Edit
              </Button>
            </Stack>
            <Box ml={2} my={4}>
              <Stack spacing="4" direction="row" alignItems="center">
                <Text as="h2" fontWeight="bold" mr={1} lineHeight={1.2} mb={2} fontSize="2xl">
                  {props.data?.name || ''}
                </Text>
                <Badge
                  bg={getVariantColor(props.data?.status)}
                  variant="solid"
                  color={getFontColor(props.data?.status)}
                  key={props.id}
                  maxWidth="130px"
                  height="min-content"
                  fontSize="md"
                >
                  {props.data?.status || ''}
                </Badge>
              </Stack>
              {!clientView && (
                <Stack direction="row" spacing="2" align="center">
                  <Icon name="time" size="14px" />
                  <Text fontWeight="semibold">{props.data?.hours || '0'} Hrs</Text>
                </Stack>
              )}
              <Divider bg="gray.500" />
              <Box fontSize="sm" fontWeight="light" width="350px" minH="50px">
                {summary === null ? (
                  <Text>{props.data?.description || ''}</Text>
                ) : (
                  <Text>
                    {summary}
                    {readMore && details}
                    <Link
                      fontWeight="normal"
                      onClick={() => {
                        setReadMore(!readMore)
                      }}
                      textDecoration="underline"
                    >
                      {linkName}
                    </Link>
                  </Text>
                )}
              </Box>
            </Box>
          </Stack>
          <Vertex type="source" position={Position.Right} isValidConnection={isValidConnection} />
        </Box>
        <Button
          aria-label=""
          bg="gray.300"
          height="100%"
          borderRadius="0px 4px 4px 0px"
          shadow="1px 1px 2px"
          my="auto"
          _hover={{ bg: 'brand.700', color: 'white' }}
          width="25px"
          onClick={() => {
            setParent(props.id)
            setIsPlatButton(true)
            onOpenComponentModal()
          }}
          isDisabled={isApproved || user?.role?.name === 'Client'}
        >
          <Flex transform="rotate(-90deg)" align="center">
            <Icon name="plus-square" my={2} mr={2} />
            New Component
          </Flex>
        </Button>
      </Flex>
    </Flex>
  )
}
export default memo(PlatformNode)
