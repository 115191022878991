/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import {
  Flex,
  Text,
  Avatar,
  AvatarBadge,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
  Box,
  Badge,
  Button
} from '@chakra-ui/core'
import { formatError } from '../../../../utils'
import { Formik, Form } from 'formik'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../../constants'
import { Container } from '../../../../components/ResponsiveGrid'
import { ConnectedFormGroup } from '../../../../components/FormElements'
import * as Yup from 'yup'
import { useAuthContext } from '../../../../context/AuthProvider'
import moment from 'moment'
import _ from 'lodash'
import { useQuoteContext } from '../../../../context/QuoteProvider'
import { Change } from '../..'
import { useUpdateQuoteMutation } from '../../../../generated/graphql'
import { useParams } from 'react-router-dom'

type NodeCommentsProps = {
  setElements: any
  comments: any
  node: any
  data: any
  refetch: any
}

const AddCommentFormValidation = Yup.object().shape({
  body: Yup.string().required('A comment is required.')
})

//get data (will prop drilling be an issue? check how state works in evernote)

const NodeComments: React.FC<NodeCommentsProps> = (props) => {
  const toast = useToast()
  const { user } = useAuthContext()
  const params: any = useParams()
  const { setChangedStatus, isApproved, setSelectedNode } = useQuoteContext()
  const [updateQuote] = useUpdateQuoteMutation({
    onCompleted: () => {
      // toast({ description: 'Quote updated.', ...SUCCESS_TOAST })
      props.refetch()
    },
    onError: (error) => {
      toast({ description: 'There was an error updating quote.', ...ERROR_TOAST })
      console.log(error)
    }
  })

  const updateComment = (commentID: any, acknowledged?: boolean, deleted?: boolean) => {
    try {
      let commentBody = ''
      props.setElements((els: any) => {
        let updatedComments
        let thisIndex
        let falseIndex
        if (props.node.type === 'featureNode') {
          const parentIndex = _.findIndex(els, { id: props.node.data.parent })
          thisIndex = _.findIndex(els[parentIndex].data.features, {
            id: props.node.id
          })
          updatedComments = els[parentIndex].data.features[thisIndex].data.comments

          const commentIndex = _.findIndex(updatedComments, { id: commentID })

          if (acknowledged) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              acknowledged: true,
              updated_at: moment().format('DD/MM/YYYY')
            }
          }
          if (deleted) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              deleted: true,
              updated_at: moment().format('DD/MM/YYYY')
            }
          }

          commentBody = updatedComments[commentIndex].body

          //Checking if any comments.acknowledgement is still false, if not then change feature status to accepted and set changedStatus to this node

          falseIndex = _.findIndex(updatedComments, { acknowledged: false })

          if (falseIndex === -1) {
            els[parentIndex].data.features[thisIndex].data = {
              ...els[parentIndex].data.features[thisIndex].data,
              status: 'ACCEPTED',
              comments: updatedComments
            }
            setChangedStatus(els[parentIndex].data.features[thisIndex])
          } else {
            els[parentIndex].data.features[thisIndex].data = {
              ...els[parentIndex].data.features[thisIndex].data,
              comments: updatedComments
            }
          }

          setSelectedNode({
            id: els[parentIndex].data.features[thisIndex].id,
            type: els[parentIndex].data.features[thisIndex].type,
            data: els[parentIndex].data.features[thisIndex].data,
            position: els[parentIndex].data.features[thisIndex].position
          })
        } else {
          thisIndex = _.findIndex(els, { id: props.node.id })
          updatedComments = els[thisIndex].data.comments

          const commentIndex = _.findIndex(updatedComments, { id: commentID })

          if (acknowledged) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              acknowledged: true,
              updated_at: moment().format('DD/MM/YYYY')
            }
          }

          if (deleted) {
            updatedComments[commentIndex] = {
              ...updatedComments[commentIndex],
              deleted: true,
              updated_at: moment().format('DD/MM/YYYY')
            }
          }

          commentBody = updatedComments[commentIndex].body

          //Checking if any comments.acknowledgement is still false, if not then change component status to accepted and  set changedStatus to a child (or this if no children) no that the status check starts at the lowest level

          falseIndex = _.findIndex(updatedComments, { acknowledged: false })

          if (falseIndex === -1) {
            els[thisIndex].data = {
              ...els[thisIndex].data,
              status: 'ACCEPTED',
              comments: updatedComments
            }
            setChangedStatus(els[thisIndex])
          } else {
            els[thisIndex].data = {
              ...els[thisIndex].data,
              comments: updatedComments
            }
          }
          setSelectedNode({
            id: els[thisIndex].id,
            type: els[thisIndex].type,
            data: els[thisIndex].data,
            position: els[thisIndex].position
          })
        }
        return els
      })

      const changelist = props?.data?.quote?.changes || []
      const change: Change = {
        time: moment().format('DD/MM/YYYY, hh:mm A'),
        user: user?.username,
        description: `Comment Acknowledged - '${commentBody}'`,
        type: 'comment',
        typeID: commentID
      }
      updateQuote({
        variables: {
          id: params.id,
          data: {
            changes: changelist.concat(change)
          }
        }
      })
    } catch (error) {
      toast({ description: 'Error adding comment.', ...ERROR_TOAST })
    }
  }

  return (
    <>
      <Flex flexDirection="column">
        <Text paddingBottom={1}>Comments</Text>
        {props.comments?.map((comment: any) => (
          <Container paddingBottom={2} key={comment.id}>
            <Flex flexDirection="row">
              <Avatar size="sm" src={comment?.user} name={comment?.user?.username}></Avatar>
              <Container>
                <Text>
                  <Flex>
                    {comment?.user?.username} &bull;
                    <Text color="gray.500" ml={1}>
                      {comment?.updated_at}
                    </Text>
                  </Flex>
                </Text>
                <Text>{comment?.body}</Text>
              </Container>
              <Flex width="220px">
                {comment?.acknowledged === false ? (
                  <Button
                    variantColor="brand"
                    key={comment.id}
                    width="120px"
                    my="auto"
                    px={6}
                    isDisabled={user?.role?.name === 'Client'}
                    onClick={() => updateComment(comment.id, true)}
                  >
                    Acknowledge
                  </Button>
                ) : (
                  <Badge bg="green.400" color="white" display="flex" alignItems="center" my="auto">
                    <Text fontWeight="bold">Acknowledged</Text>
                  </Badge>
                )}
              </Flex>
              <IconButton
                aria-label="delete"
                variantColor="red"
                key={comment.id}
                ml={1}
                my="auto"
                icon="delete"
                onClick={() => updateComment(comment.id, false, true)}
              />
            </Flex>
          </Container>
        ))}
      </Flex>
      <Flex>
        <Avatar size="sm" src={user?.profilePicture?.url} name={user?.username}>
          <AvatarBadge size="1em" bg="green.500" />
        </Avatar>
        <Formik
          validationSchema={AddCommentFormValidation}
          initialValues={{
            body: ''
          }}
          onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
            setStatus(null)
            try {
              let commentID: any = ''
              props.setElements((els: any) =>
                els.map((el: any) => {
                  if (el.id === props.node.id) {
                    if (el.data.comments) {
                      commentID = el.data.comments.length
                    } else {
                      commentID = 0
                      el.data.comments = []
                    }
                    el.data.comments.push({
                      id: commentID,
                      body: values.body,
                      created_at: moment().format('DD/MM/YYYY'),
                      updated_at: moment().format('DD/MM/YYYY'),
                      user: { username: user?.username, url: '' },
                      acknowledged: false
                    })
                    el.data = {
                      ...el.data,
                      status: 'NEEDS REVIEW',
                      comments: el.data.comments
                    }
                    setSelectedNode({
                      id: el.id,
                      type: el.type,
                      data: el.data,
                      position: el.position
                    })
                    setChangedStatus(el)
                  } else if (
                    el.id === props.node.data.parent &&
                    props.node.type === 'featureNode'
                  ) {
                    const newFeats = el.data.features.filter(
                      (feat: any) => feat.id !== props.node.id
                    )
                    if (props.node.data.comments) {
                      commentID = props.node.data.comments.length
                    } else {
                      commentID = 0
                      props.node.data.comments = []
                    }
                    props.node.data.comments.push({
                      id: commentID,
                      body: values.body,
                      created_at: moment().format('DD/MM/YYYY'),
                      updated_at: moment().format('DD/MM/YYYY'),
                      user: { username: user?.username, url: '' },
                      acknowledged: false
                    })
                    newFeats.push({
                      ...props.node,
                      data: {
                        ...props.node.data,
                        status: 'NEEDS REVIEW',
                        comments: props.node.data.comments
                      }
                    })
                    el.data = {
                      ...el.data,
                      status: 'NEEDS REVIEW',
                      features: newFeats
                    }
                    const updatedNodeIndex = _.findIndex(el.data.features, { id: props.node.id })
                    setSelectedNode({
                      id: el.data.features[updatedNodeIndex].id,
                      type: el.data.features[updatedNodeIndex].type,
                      data: el.data.features[updatedNodeIndex].data,
                      position: el.data.features[updatedNodeIndex].position
                    })
                    //setChanged Status to this node
                    setChangedStatus(el)
                  }
                  return el
                })
              )
              //Updating change log
              const changelist = props?.data?.quote?.changes || []
              const change: Change = {
                time: moment().format('DD/MM/YYYY, hh:mm A'),
                user: user?.username,
                description: `New comment added: '${values.body}'`,
                type: 'comment',
                typeID: commentID
              }
              updateQuote({
                variables: {
                  id: params.id,
                  data: {
                    changes: changelist.concat(change)
                  }
                }
              })
              values.body = ''
              toast({ description: 'Comment added.', ...SUCCESS_TOAST })
            } catch (error) {
              setStatus(formatError(error))
              console.log(error)
              toast({ description: 'Error adding comment.', ...ERROR_TOAST })
            }
          }}
        >
          {({ values, isSubmitting, submitForm, resetForm, setFieldValue }) => (
            <Box width="100%">
              <Form>
                <InputGroup size="md" ml={3}>
                  <ConnectedFormGroup name="body" placeholder=" Enter your comments here" pr={39} />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      icon="chat"
                      aria-label="Comment Button"
                      type="submit"
                      isLoading={isSubmitting}
                      isDisabled={isApproved}
                    />
                  </InputRightElement>
                </InputGroup>
              </Form>
            </Box>
          )}
        </Formik>
      </Flex>
    </>
  )
}

export default NodeComments
