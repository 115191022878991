import * as React from 'react'
import { Button } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { Variants } from 'framer-motion'
import { MotionFlex } from '..'
import Copyright from '../Copyright'
import Version from '../Version'

const PanelWrapper = styled(MotionFlex)`
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;
  display: flex;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    position: relative;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const variants: Variants = {
  hidden: {
    x: '-350px'
  },
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 40
    }
  }
}

const SideSlider: React.FC = ({ children }) => {
  return (
    <PanelWrapper
      p={5}
      m={[5, 0]}
      bg="white"
      rounded={['md', 0]}
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      {children}
      <Version />
      <Button
        variant="link"
        variantColor="blue"
        onClick={() => window.open(process.env.REACT_APP_API_HOST + '/connect/google', '_self')}
      >
        Architect Login
      </Button>
      <Copyright mt={10} />
    </PanelWrapper>
  )
}

export default SideSlider
